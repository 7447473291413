<style>
  @media (max-width: 1024px) {
    .sm-spacer {
      margin-top: .5rem;
    }
  }
  .settings-collapse {
    margin-bottom: 1rem;
  }
  .hard-border {
    border-radius: 0 !important;
  }
  .c-dashboard-row {
    margin-top: 1px;
    overflow: hidden;
    height: 100%;
    width: 100%;
  }
  .c-dashboard-col {
    padding-left: unset;
    padding-right: unset;
    height: 100% !important;
  }
  .c-dashboard-card {
    border-radius: 0 !important;
    border-left: 1px solid #222736 !important;
    height: 100% !important;
    padding: unset;
  }
  @media (max-width: 1024px) {
    .c-players-col {
      height: 100% !important;
      width: 100%;
      float: left;
    }
    .c-feed-col {
      height: 100% !important;
      float: left;
      width: 100%;
      border-top: 2px solid #222736 !important;
    }
    .c-player-connection {
      display: none;
    }
    .c-player-names {
      margin-left: 1rem;
      margin-right: 1rem;
    }
    .c-player-badge {
      display: none;
    }
  }
  @media (min-width: 1024px) {
    .c-players-col {
      height: 100% !important;
      border-right: 2px solid #222736 !important;
      /* width: 70rem; */
      width: 67%;
      float: left;
    }
    .c-feed-col {
      margin-left: 0.5rem;
      height: 100% !important;
      /* border-left: 1px solid #222736 !important; */
      float: left;
      /* width: 33.5rem; */
      width: 32.2%;
    }
    .c-player-connection {
      margin-left: 1rem;
      margin-right: 1rem;
      width: 9rem;
    }
    .c-player-names {
      margin-right: 1rem;
      margin-left: 1rem;
      width: 9rem;
    }
    .c-player-playtime {
      margin-left: 1rem;
      margin-right: 1rem;
    }
    .c-player-badge {
      margin-left: 0.25rem;
      margin-right: 0.25rem;
      margin-top: 0.5rem;
    }
  }
  .c-player {
    margin-bottom: 0 !important;
    border-bottom: 1px solid #222736 !important;
  }
  .c-intersecting-row {
    position: relative;
    left: 0.5rem;
    top: -1.75rem;

  }
  .c-player-kdratio {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .c-admins {
    margin-top: -2rem !important;
    margin-left: 4rem;
    margin-bottom: -1.5rem;
  }
  .c-pin-btn {
    margin-bottom: -1.25rem;
  }
  .c-feed-container {

  }
  .c-player-row {
    height: 2rem;
  }

  .c-player-img {
    width: 2rem;
  }

  .c-player-ping {
    /*width: 5rem;*/
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .c-feed {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .c-feed-text {
    word-break: break-all;
  }
  .c-feed-row:hover {
    cursor: pointer;
    filter: brightness(150%);
  }
  .c-console-header {
    padding: 0.5rem !important;
  }
  .c-console-container {
    padding: unset !important;
    height: 25rem;
  }
  .c-console-messages {
    max-height: 92%;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .c-console-message {
    white-space: pre-line;
  }
  .c-console {
    background: black;
    color: white;
    height: 100%;
    position: relative;
  }
  .c-console-prompt-row {
    position:absolute;
    bottom:0;
    width: 100%;
  }
  .c-console-prompt {
    background: black;
    border: none;
    outline: none;
    color: white !important;
    width: 95%;
    display: inline-block;
    caret-shape: block;
  }
  .c-console-prompt-header {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
    display: inline-block;
  }
  .leaflet-container {
    background: unset;
    outline: 0;
  }
  .c-player-marker {
    box-shadow: 1px 1px 1px grey;
    -moz-box-shadow: 1px 1px 1px grey;
    -webkit-box-shadow: 1px 1px 1px grey;
    z-index: 901 !important;
  }
  .c-vehicle-marker {
    z-index: 500 !important;
  }
  .c-vehicle-marker-driving {
    z-index: 500 !important;
    text-shadow: 0 0 3px black;
    color: #4287f5;
  }
  .c-vehicle-marker i {
    text-shadow: 0 0 3px black;
  }
  .c-event-marker {
    z-index: 499 !important;
  }
  .c-event-marker i {
    color: #e83e8c;
    text-shadow: 0 0 3px black;
  }
  .c-highlight-marker {
    z-index: 999999999999 !important;
    color: #ff00f7;
    text-shadow: 0 0 3px black;
  }
  .c-truncate-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .leaflet-container {
    width: 100vw;
    height: 100vh;
  }
</style>
<script>

import {mapGetters} from "vuex";
import {WorkerState} from "@/enums";
import {parseISO8601String, get_acsrf_token} from "@/methods";
import Multiselect from "vue-multiselect";
import L from 'leaflet';
import { HalfCircleSpinner } from 'epic-spinners';
import {LMarker,LTileLayer,LMap} from "vue2-leaflet";
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead';
import { vanillaObjects } from "./items/dayz";
import CopyableText from "@/components/CopyableText";

const humanizeDuration = require("humanize-duration");
const customHumanizer = humanizeDuration.humanizer({
  language: "shortEn",
  languages: {
    shortEn: {
      y: () => "y",
      mo: () => "mo",
      w: () => "w",
      d: () => "d",
      h: () => "h",
      m: () => "m",
      s: () => "s",
      ms: () => "ms",
    },
  },
});

const uniqueElementsBy = (arr, fn) =>
    arr.reduce((acc, v) => {
      if (!acc.some(x => fn(v, x))) acc.push(v);
      return acc;
    }, []);

const yx = L.latLng;
const xy = function(x, y) {
  if (L.Util.isArray(x)) { // When doing xy([x, y]);
    return yx(x[1], x[0]);
  }
  return yx(y, x); // When doing xy(x, y);
};

if (!String.format) {
  String.format = function(format) {
    var args = Array.prototype.slice.call(arguments, 1);
    return format.replace(/{(\d+)}/g, function(match, number) {
      return typeof args[number] != 'undefined'
          ? args[number]
          : match
          ;
    });
  };
}

(function(global){
  var MarkerMixin = {
    _updateZIndex: function (offset) {
      this._icon.style.zIndex = this.options.forceZIndex ? (this.options.forceZIndex + (this.options.zIndexOffset || 0)) : (this._zIndex + offset);
    },
    setForceZIndex: function(forceZIndex) {
      this.options.forceZIndex = forceZIndex ? forceZIndex : null;
    }
  };
  if (global) global.include(MarkerMixin);
})(L.Marker);

export default {
  props: ['server_id', 'options', 'is_mobile'],
  components: {
    HalfCircleSpinner,
    // Map
    // eslint-disable-next-line vue/no-unused-components
    LMap,
    // eslint-disable-next-line vue/no-unused-components
    LTileLayer,
    // eslint-disable-next-line vue/no-unused-components
    LMarker,
    VueBootstrapTypeahead,
    CopyableText,
    Multiselect
  },
  computed: {
    ...mapGetters([
      'getServer',
      'getUILanguage',
      'getPersonaName',
      'getPersonaAvatar',
      'getAccountId',
      'getDTLocale',
      'getPreferences'
    ]),
    filteredPlayers: function() {
      let players = [];
      if(this.searchQuery) {
        this.players.forEach((player) => {
          let relevant = false;
          if(player.persona && player.persona.profile && player.persona.profile.name && (player.persona.profile.name.toLowerCase().includes(this.searchQuery.toLowerCase()) || player.persona.profile.name.toLowerCase() === this.searchQuery.toLowerCase())) relevant = true;
          else if(player.gamedata.player_name.toLowerCase().includes(this.searchQuery.toLowerCase()) || player.gamedata.player_name.toLowerCase() === this.searchQuery.toLowerCase()) relevant = true;
          //else if(player.connection.ipv4.includes(this.searchQuery.toLowerCase()) || player.connection.ipv4 === this.searchQuery.toLowerCase()) relevant = true;
          if(relevant) players.push(player);
        });
      } else {
        players = this.players;
      }
      let k = 'created_at';
      switch(this.sortField) {
        case 'playtime': {
          k = 'created_at';
          break;
        }
        case 'kdratio': {
          players = players.sort((a, b) => {
            if(this.calcKDRatio(a) < this.calcKDRatio(b)) return -1 * this.sortDirection;
            if(this.calcKDRatio(a) > this.calcKDRatio(b)) return 1 * this.sortDirection;
            return 0;
          });
          return players;
        }
        case 'name': {
          // gamedata.player_name
          players = players.sort((a, b) => {
            if(a.gamedata.player_name < b.gamedata.player_name) return -1 * this.sortDirection;
            if(a.gamedata.player_name > b.gamedata.player_name) return 1 * this.sortDirection;
            return 0;
          });
          return players;
        }
      }
      players = players.sort((a, b) => {
        if(a[k] < b[k]) return -1 * this.sortDirection;
        if(a[k] > b[k]) return 1 * this.sortDirection;
        return 0;
      });
      return players;
    },
    reversedFeed: function() {
      return this.feed.slice().reverse();
    }
  },
  methods: {
    handleError(error) {
      console.log(`[ERROR] ${error}`);
      this.error = true;
    },
    adjustSize() {
      try {
        this.height = visualViewport.height - 70 - 60 - 65;
        this.width = visualViewport.width;
      } catch (e) {
        this.height = (window.outerHeight - 70 - 60 - 65) * 0.87;
        this.width = (window.outerWidth);
      }
      if(this.width < 1024) {
        this.mobileView = true;
        this.feedHeight = this.height - 100;
      } else {
        this.mobileView = false;
        this.feedHeight = this.height - 100;
      }
    },
    playerPlaytime(date, created_at) {
      if(this.mobileView) return customHumanizer(date - created_at.getTime(), { round: true, largest: 2, units: ['d', 'h', 'm'], language: 'shortEn' });
      return customHumanizer(date - created_at.getTime(), { round: true, largest: 2, units: ['d', 'h', 'm'], language: 'shortEn' });
    },
    calcKDRatio(player) {
      if(!player) return 0.00;
      let kills = player.stats.kills || 0;
      let deaths = Math.max(player.stats.deaths || 0, 1);
      return Number(kills / deaths).toFixed(2);
    },
    displayEvent(event) {
      let blockedEvents = ['user.loaded', 'rcon.console', 'server.state'];
      return !blockedEvents.includes(event.event);
    },
    localizeEvent(event, includePositions = true) {
      switch(event.event) {
        case 'user.join': return this.$t('server.dashboard.feed.events.user.join');
        case 'user.leave': return this.$t('server.dashboard.feed.events.user.leave', {playtime: humanizeDuration(event.parameters.player.playtime * 1000)});
        case 'user.kicked': return this.$t('server.dashboard.feed.events.user.kicked', {
          reason: event.parameters.player.reason, playtime: humanizeDuration(event.parameters.player.playtime * 1000)
        });
        case 'user.chat': return `(${event.parameters.chat.channel}) > ${event.parameters.chat.message}`;
        case 'player.interact': {
          let translationKey = `gameactions.${event.parameters.action}`;
          event.parameters.action_string = this.$t(translationKey);
          if(event.parameters.action_string === translationKey) event.parameters.action_string = event.parameters.action;
          if(event.parameters.action === 'ActionRaiseFlag') delete event.parameters.item;
          if(event.parameters.item) {
            if(!event.parameters.target) {
              event.parameters.target = event.parameters.item;
              return `${this.$t(`events.player.interact_woitem`, event.parameters)}`;
            } else return `${this.$t(`events.player.interact`, event.parameters)}`;
          } else {
            return `${this.$t(`events.player.interact_woitem`, event.parameters)}`;
          }
        }
        case 'player.place': {
          return `${this.$t(`events.player.place`, event.parameters)}`;
        }
        case 'player.death': {
          if(event.parameters.murderer) {
            return `${this.$t('events.player.killed')}`;
          } else {
            return `${this.$t('events.player.death')}`;
          }
        }
        case 'player.envdeath': {
          if(includePositions) {
            if (event.parameters.cause === '__environment') {
              return `${this.$t('events.player.death_natural')} (${event.parameters.player.position})`;
            } else if (event.parameters.cause === '__infected') {
              return `${this.$t('events.player.death_infected')} (${event.parameters.player.position})`;
            } else if (event.parameters.cause === '__bleedout') {
              return `${this.$t('events.player.death_bleedout')} (${event.parameters.player.position})`;
            } else if (event.parameters.cause === '__starvation') {
              return `${this.$t('events.player.death_starvation')} (${event.parameters.player.position})`;
            } else if (event.parameters.cause === '__animal') {
              return `${this.$t('events.player.death_animal', {animal: event.parameters.object})} (${event.parameters.player.position})`;
            } else if (event.parameters.cause === '__object') {
              return `${this.$t('events.player.death_object', {object: event.parameters.object})} (${event.parameters.player.position})`;
            } else if (event.parameters.cause === '__explosion') {
              return `${this.$t('events.player.death_explosion')} (${event.parameters.player.position})`;
            } else if (event.parameters.cause === '__fall_damage') {
              return `${this.$t('events.player.death_falldamage')} (${event.parameters.player.position})`;
            }
            return `${this.$t('events.player.death_natural')} (${event.parameters.player.position})`;
          } else {
            if (event.parameters.cause === '__environment') {
              return `${this.$t('events.player.death_natural')}`;
            } else if (event.parameters.cause === '__infected') {
              return `${this.$t('events.player.death_infected')}`;
            } else if (event.parameters.cause === '__bleedout') {
              return `${this.$t('events.player.death_bleedout')}`;
            } else if (event.parameters.cause === '__starvation') {
              return `${this.$t('events.player.death_starvation')}`;
            } else if (event.parameters.cause === '__animal') {
              return `${this.$t('events.player.death_animal', {animal: event.parameters.object})}`;
            } else if (event.parameters.cause === '__object') {
              return `${this.$t('events.player.death_object', {object: event.parameters.object})}`;
            } else if (event.parameters.cause === '__explosion') {
              return `${this.$t('events.player.death_explosion')}`;
            } else if (event.parameters.cause === '__fall_damage') {
              return `${this.$t('events.player.death_falldamage')}`;
            }
            return `${this.$t('events.player.death_natural')}`;
          }
        }
        case 'player.suicide': {
          if(includePositions) return `${this.$t('events.player.suicide')} (${event.parameters.player.position})`;
          else return `${this.$t('events.player.suicide')}`;
        }
        case 'server.message': {
          return this.$t('events.server.message', event.parameters);
        }
        case 'server.directmessage': {
          return this.$t('events.server.message', event.parameters);
        }
        default: return event.event + ' | ' + event.parameters;
      }
    },
    showKickModal(gamesessionId) {
      this.kick.target = gamesessionId;
      this.kick.reason = null;
      this.$refs.kickModal.show();
    },
    showKickAllModal() {
      this.$refs.kickAllModal.show();
    },
    async kickAllPlayers() {
      this.inProgress = true;
      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token,
          reason: this.kickAll.reason
        };
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/server/${this.server_id}/kick-all`, {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        });
        if(response.ok) {
          this.kickAll.reason = null;
          this.$toast.success(this.$t('server.dashboard.players.kickall.success'));
          this.$refs.kickAllModal.hide();
        } else {
          if(response.status === 429) {
            this.$toast.warning(this.$t('error.server.ratelimit.short'));
          } else if(response.status === 403) {
            await this.$swal({
              icon: 'error',
              title: 'Forbidden'
            });
            await this.$router.push({name: 'dashboard'});
          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        console.log(`[ERROR] ${error}`);
        this.$toast.error(this.$t('error.server.generic.message'));
      }
      this.inProgress = false;
    },
    async kickPlayer() {
      this.inProgress = true;
      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token,
          gamesession_id: this.kick.target,
          reason: this.kick.reason
        };
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/server/${this.server_id}/kick-player`, {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        });
        if(response.ok) {
          this.kick.target = null;
          this.kick.reason = null;
          this.$toast.success(this.$t('server.dashboard.players.kick.success'));
          this.$refs.kickModal.hide();
        } else {
          if(response.status === 429) {
            this.$toast.warning(this.$t('error.server.ratelimit.short'));
          } else if(response.status === 403) {
            await this.$swal({
              icon: 'error',
              title: 'Forbidden'
            });
            await this.$router.push({name: 'dashboard'});
          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        console.log(`[ERROR] ${error}`);
        this.$toast.error(this.$t('error.server.generic.message'));
      }
      this.inProgress = false;
    },
    showMessageModal(gamesessionId) {
      this.message.target = gamesessionId;
      this.message.reason = null;
      this.$refs.messageModal.show();
    },
    async messagePlayer() {
      if(!this.message.content || this.message.content.length === 0) return;
      this.inProgress = true;
      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token,
          gamesession_id: this.message.target,
          content: this.message.content
        };
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/server/${this.server_id}/message-private`, {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        });
        if(response.ok) {
          this.message.target = null;
          this.message.content = null;
          this.$toast.success(this.$t('server.dashboard.players.message.success'));
          this.$refs.messageModal.hide();
        } else {
          if(response.status === 429) {
            this.$toast.warning(this.$t('error.server.ratelimit.short'));
          } else if(response.status === 403) {
            await this.$swal({
              icon: 'error',
              title: 'Forbidden'
            });
            await this.$router.push({name: 'dashboard'});
          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        console.log(`[ERROR] ${error}`);
        this.$toast.error(this.$t('error.server.generic.message'));
      }
      this.inProgress = false;
    },
    toggleConsole() {
      this.$refs.consoleModal.show();
      setTimeout(() => {
        try {
          this.$refs.consoleMessages.scrollTop = this.$refs.consoleMessages.scrollHeight;
          // eslint-disable-next-line no-empty
        } catch(e) {}
        this.$refs.consolePrompt.select();
      }, 1);
    },
    toggleServerPanel() {
      this.$refs.serverModal.show();
    },
    async consoleEnterHandler() {
      if(this.console.command === 'clear') {
        this.console.command = null;
        this.console.messages = [];
        return;
      }
      this.$refs.consolePrompt.disabled = true;
      this.console.pending = true;
      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token,
          command: this.console.command
        };
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/server/${this.server_id}/console`, {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        });
        if(response.ok) {
          if(this.console.command.charAt(0) === '#') this.console.pending = false;
          else {
            setTimeout(() => {
              this.console.pending = false;
            }, 5000);
          }
          this.console.command = null;
          setTimeout(() => {
            this.$refs.consolePrompt.select();
          }, 1);
        } else {
          if(response.status === 429) {
            this.$toast.warning(this.$t('error.server.ratelimit.short'));
          } else if(response.status === 403) {
            this.$toast.error('Forbidden');
          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        this.console.pending = false;
        console.log(`[ERROR] ${error}`);
        this.$toast.error(this.$t('error.server.generic.message'));
      }
      this.$refs.consolePrompt.disabled = false;
    },
    async messageServer() {
      if(!this.serverMessage || this.serverMessage.length === 0) return;
      this.inProgress = true;
      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token,
          content: this.serverMessage
        };
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/server/${this.server_id}/message-server`, {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        });
        if(response.ok) {
          this.$toast.success(this.$t('server.dashboard.feed.send.success'));
          this.serverMessage = null;
        } else {
          if(response.status === 429) {
            this.$toast.warning(this.$t('error.server.ratelimit.short'));
          } else if(response.status === 403) {
            await this.$swal({
              icon: 'error',
              title: 'Forbidden'
            });
            await this.$router.push({name: 'dashboard'});
          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        console.log(`[ERROR] ${error}`);
        this.$toast.error(this.$t('error.server.generic.message'));
      }
      this.inProgress = false;
    },
    async toggleLock() {
      this.inProgress = true;
      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token
        };
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/server/${this.server_id}/state-lock`, {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        });
        if(response.ok) {
          this.$toast.success(this.$t('server.dashboard.server.lock.success'));
        } else {
          if(response.status === 429) {
            this.$toast.warning(this.$t('error.server.ratelimit.short'));
          } else if(response.status === 403) {
            this.$toast.error('Forbidden');
          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        console.log(`[ERROR] ${error}`);
        this.$toast.error(this.$t('error.server.generic.message'));
      }
      this.inProgress = false;
    },
    async toggleWhitelist() {
      this.inProgress = true;
      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token
        };
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/server/${this.server_id}/state-whitelist`, {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        });
        if(response.ok) {
          this.$toast.success(this.$t('server.dashboard.server.whitelist.success'));
        } else {
          if(response.status === 429) {
            this.$toast.warning(this.$t('error.server.ratelimit.short'));
          } else if(response.status === 403) {
            this.$toast.error('Forbidden');
          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        console.log(`[ERROR] ${error}`);
        this.$toast.error(this.$t('error.server.generic.message'));
      }
      this.inProgress = false;
    },
    async skipShutdown() {
      this.inProgress = true;
      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token
        };
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/server/${this.server_id}/shutdown-skip`, {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        });
        if(response.ok) {
          this.$toast.success(this.$t('server.dashboard.server.shutdown.skipped'));
        } else {
          if(response.status === 429) {
            this.$toast.warning(this.$t('error.server.ratelimit.short'));
          } else if(response.status === 403) {
            this.$toast.error('Forbidden');
          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        console.log(`[ERROR] ${error}`);
        this.$toast.error(this.$t('error.server.generic.message'));
      }
      this.inProgress = false;
    },
    async shutdownNow() {
      this.inProgress = true;
      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token
        };
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/server/${this.server_id}/shutdown-now`, {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        });
        if(response.ok) {
          this.$toast.success(this.$t('server.dashboard.server.shutdown.success'));
        } else {
          if(response.status === 429) {
            this.$toast.warning(this.$t('error.server.ratelimit.short'));
          } else if(response.status === 403) {
            this.$toast.error('Forbidden');
          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        console.log(`[ERROR] ${error}`);
        this.$toast.error(this.$t('error.server.generic.message'));
      }
      this.inProgress = false;
    },
    async scheduleShutdown() {
      this.$refs.serverModal.hide();
      let result = await this.$swal({
        input: 'text',
        title: this.$t('server.dashboard.server.shutdown.prompt'),
        showCancelButton: true,
        confirmButtonText: this.$t('server.dashboard.server.shutdown.scheduled'),
        confirmButtonColor: '#f46a6a',
        cancelButtonColor: '#c3cbe4',
        showLoaderOnConfirm: true,
        preConfirm: async (shutdown_delay) => {
          let acsrf_token = await get_acsrf_token();
          let payload = {
            acsrf_token: acsrf_token,
            delay: shutdown_delay
          };
          let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/server/${this.server_id}/shutdown-delayed`, {
            method: 'POST',
            body: JSON.stringify(payload),
            credentials: 'include'
          });
          if(response.ok) {
            return response.json();
          }
          this.$swal.showValidationMessage(this.$t('error.server.generic.message'));
        }
      }).then((result) => {
        if(result.isConfirmed) {
          this.$toast.success(this.$t('server.dashboard.server.shutdown.success'));
        }
      });
    },
    async showHealModal(playerId) {
      let player = this.getPlayer(playerId);
      if(!player) return;
      this.healTarget = {
        gamesessionId: playerId,
        name: player.gamedata.player_name
      };
      this.$refs.healModal.show();
    },
    async healPlayer() {
      this.inProgress = true;
      try {
        let acsrf_token = await get_acsrf_token();

        let payload = {
          acsrf_token: acsrf_token,
          gamesession_id: this.healTarget.gamesessionId
        };
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/server/${this.server_id}/game-heal`, {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        });
        if(response.ok) {
          this.$toast.success(this.$t('server.dashboard.game.heal.confirm.success'));
          this.$refs.healModal.hide();
        } else {
          if(response.status === 429) {
            this.$toast.warning(this.$t('error.server.ratelimit.short'));
          } else if(response.status === 403) {
            this.$toast.error('Forbidden');
          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        console.log(`[ERROR] ${error}`);
        this.$toast.error(this.$t('error.server.generic.message'));
      }
      this.inProgress = false;
    },
    async showKillModal(playerId) {
      let player = this.getPlayer(playerId);
      if(!player) return;
      this.killTarget = {
        gamesessionId: playerId,
        name: player.gamedata.player_name
      };
      this.$refs.killModal.show();
    },
    async killPlayer() {
      this.inProgress = true;
      try {
        let acsrf_token = await get_acsrf_token();

        let payload = {
          acsrf_token: acsrf_token,
          gamesession_id: this.killTarget.gamesessionId
        };
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/server/${this.server_id}/game-kill`, {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        });
        if(response.ok) {
          this.$toast.success(this.$t('server.dashboard.game.kill.confirm.success'));
          this.$refs.killModal.hide();
        } else {
          if(response.status === 429) {
            this.$toast.warning(this.$t('error.server.ratelimit.short'));
          } else if(response.status === 403) {
            this.$toast.error('Forbidden');
          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        console.log(`[ERROR] ${error}`);
        this.$toast.error(this.$t('error.server.generic.message'));
      }
      this.inProgress = false;
    },
    async showItemSpawnModal(playerId) {
      let player = this.getPlayer(playerId);
      if(!player) return;
      this.spawnTarget = {
        gamesessionId: playerId,
        name: player.gamedata.player_name,
        object: null,
        quantity: 1,
        debug: false,
        stacked: false
      };
      this.$refs.spawnItemModal.show();
    },
    async spawnItemForPlayer() {
      this.inProgress = true;
      try {
        let acsrf_token = await get_acsrf_token();

        let payload = {
          acsrf_token: acsrf_token,
          gamesession_id: this.spawnTarget.gamesessionId,
          object: this.spawnTarget.object,
          quantity: this.spawnTarget.quantity,
          debug: this.spawnTarget.debug,
          stacked: this.spawnTarget.stacked
        };
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/server/${this.server_id}/game-item-spawn`, {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        });
        if(response.ok) {
          this.$toast.success(this.$t('server.dashboard.game.itemspawn.confirm.success'));
          this.$refs.spawnItemModal.hide();
        } else {
          if(response.status === 429) {
            this.$toast.warning(this.$t('error.server.ratelimit.short'));
          } else if(response.status === 403) {
            this.$toast.error('Forbidden');
          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        console.log(`[ERROR] ${error}`);
        this.$toast.error(this.$t('error.server.generic.message'));
      }
      this.inProgress = false;
    },
    async teleportPlayer() {
      this.inProgress = true;
      try {
        let acsrf_token = await get_acsrf_token();

        let payload = {
          acsrf_token: acsrf_token,
          steam64: this.teleportTarget.options.id,
          name: this.teleportTarget.options.name,
          coords: [this.mapY(this.teleportTarget.options.gameCoords[1]), this.mapX(this.teleportTarget.options.gameCoords[0])],
          units: this.map.units,
          map_size: this.map.width
        };
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/server/${this.server_id}/game-teleport`, {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        });
        if(response.ok) {
          this.$toast.success(this.$t('server.dashboard.game.teleport.confirm.success'));
          this.$refs.teleportModal.hide();
        } else {
          if(response.status === 429) {
            this.$toast.warning(this.$t('error.server.ratelimit.short'));
          } else if(response.status === 403) {
            this.$toast.error('Forbidden');
          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        console.log(`[ERROR] ${error}`);
        this.$toast.error(this.$t('error.server.generic.message'));
      }
      this.inProgress = false;
    },
    async refreshPlayers() {
      try {
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/server/${this.server_id}/GSM`, {
          method: 'GET',
          credentials: 'include'
        });
        if(response.ok) {
          let data = await response.json();
          this.players = [];
          data.sessions.forEach((player) => {
            player.created_at = parseISO8601String(player.created_at);
            this.players.push(player);
          });
        } else {
          if(response.status === 429) {
            this.$toast.warning(this.$t('error.server.ratelimit.short'));
          } else if(response.status === 403) {
            await this.$swal({
              icon: 'error',
              title: 'Forbidden'
            });
            await this.$router.push({name: 'dashboard'});
          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        console.log(`[ERROR] ${error}`);
        this.$toast.error(this.$t('error.server.generic.message'));
      }
    },
    async fetchFeed() {
      if(this.feedLoading === true) return;
      this.feedLoading = true;
      try {
        let url = new URL(process.env.VUE_APP_ROOT_API + `v1/server/${this.server_id}/feed`);
        url.searchParams.append('index', this.feedPosition);
        let response = await fetch(url, {
          method: 'GET',
          credentials: 'include'
        });
        if(response.ok) {
          let data = await response.json();

          this.feedMax = data.max;
          data.entries.forEach((entry) => {
            entry.date = parseISO8601String(entry.date);
            this.feed.splice(this.feedPosition, 0, entry);
            this.feedPosition++;
          });

          await this.refreshFeed();

        } else {
          if(response.status === 429) {
            this.$toast.warning(this.$t('error.server.ratelimit.short'));
          } else if(response.status === 403) {
            await this.$swal({
              icon: 'error',
              title: 'Forbidden'
            });
            await this.$router.push({name: 'dashboard'});
          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        console.log(`[ERROR] ${error}`);
        this.$toast.error(this.$t('error.server.generic.message'));
      }
      this.feedLoading = false;
    },
    async refreshGameLabsPlayers() {
      try {
        let url = new URL(process.env.VUE_APP_ROOT_API + `v1/server/${this.server_id}/gameLabsPlayers`);
        url.searchParams.append('index', this.feedPosition);
        let response = await fetch(url, {
          method: 'GET',
          credentials: 'include'
        });
        if(response.ok) {
          let data = await response.json();

          this.updatePlayerMarkers(data.players);
        } else {
          throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        console.log(`[ERROR] ${error}`);
      }
    },
    async refreshGameLabsVehicles() {
      try {
        let url = new URL(process.env.VUE_APP_ROOT_API + `v1/server/${this.server_id}/gameLabsVehicles`);
        url.searchParams.append('index', this.feedPosition);
        let response = await fetch(url, {
          method: 'GET',
          credentials: 'include'
        });
        if(response.ok) {
          let data = await response.json();

          this.clearVehicleMarkers();
          data.vehicles.forEach((player) => {
            this.addVehicleMarker(player);
          });

        } else {
          throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        console.log(`[ERROR] ${error}`);
      }
    },
    async refreshGameLabsEvents() {
      try {
        let url = new URL(process.env.VUE_APP_ROOT_API + `v1/server/${this.server_id}/gameLabsEvents`);
        url.searchParams.append('index', this.feedPosition);
        let response = await fetch(url, {
          method: 'GET',
          credentials: 'include'
        });
        if(response.ok) {
          let data = await response.json();

          this.clearEventMarkers();
          data.events.forEach((player) => {
            this.addEventMarker(player);
          });

        } else {
          throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        console.log(`[ERROR] ${error}`);
      }
    },
    getPlayer(sessionId) {
      let target = null;
      this.players.forEach((player) => {
        if(player.id === sessionId) {
          target = player;
          return;
        }
      });
      return target;
    },
    getPlayerByCFID(cftoolsId) {
      let target = null;
      this.players.forEach((player) => {
        if(player.cftools_id === cftoolsId) {
          target = player;
          return;
        }
      });
      return target;
    },
    removePlayer(sessionId) {
      let players = [];
      this.players.forEach((player) => {
        if(player.id !== sessionId) players.push(player);
      });
      this.players = [];
      this.players = players;
    },
    /* ***** Event Handler ***** */
    async handleFeedEvent(event) {
      if(event.parameters.server.id !== this.server_id) return;
      let entry = Object.assign({}, event);

      this.feedMax++;
      entry.date = parseISO8601String(entry.date);
      this.feed.splice(0, 0, entry);
      this.feedPosition++;
      await this.refreshFeed();
      this.scrollFeedToBottom(false);

      if(event.event === 'user.chat') {
        if(this.preferences.audio_alert_admin) {
          if(event.parameters.chat.message.toLowerCase().includes('admin')) {
            this.audio.audio_alert_admin.play();
          }
        }
      } else if(event.event === 'player.death' && event.parameters.murderer) {
        let murderer = this.getPlayerByCFID(event.parameters.murderer.id);
        if(murderer) {
          if(!murderer.stats) murderer.stats = {kills: 0, deaths: 0};
          murderer.stats.kills++;

          let victim = this.getPlayerByCFID(event.parameters.player.id);
          if(victim) {
            if(!victim.stats) victim.stats = {kills: 0, deaths: 0};
            victim.stats.deaths++;
          }
        }
      }

      if(event.event === 'rcon.console') {
        this.console.messages.push({id: entry.id, message: event.parameters.console.message});
        this.console.pending = false;

        setTimeout(() => {
          try {
            this.$refs.consoleMessages.scrollTop = this.$refs.consoleMessages.scrollHeight;
            // eslint-disable-next-line no-empty
          } catch(e) {}
        }, 1);
      }
      // TODO: Test in production
      this.$nextTick(() => {
        let percentageTop = this.feedScrollLevel();
        if(percentageTop <= 20 && this.feedPosition > 100) {
          let startDeleteIndex = Math.max(100, this.feedPosition * 0.5);
          // console.log('Deleting up from', startDeleteIndex);
          this.feed.splice(startDeleteIndex, this.feedPosition - startDeleteIndex - 1);
          this.feedPosition = startDeleteIndex;
        }
      });

    },
    async handleServerUpdate(data) {
      if(data.server_id !== this.server_id) return;
      if(data.type === 'players') {
        data.players.forEach((playerData) => {
          let player = this.getPlayer(playerData.gamesession.id);
          if(player === null) return;
          player.live.ping = playerData.ping;
          player.live.loaded = playerData.connection.loaded;
        });
      } else if(data.type === 'player') {
        let player = this.getPlayer(data.gamesession.id);
        if(player) Object.assign(player.live, data.player.live);
      } else if(data.type === 'server') {
        this.server = data.server;
      } else if(data.type === 'gl-players') {
        if(data.reset) this.clearPlayerMarkers();
        this.updatePlayerMarkers(data.players);
      } else if(data.type === 'gl-vehicles') {
        if(data.reset) this.clearVehicleMarkers();

        if(this.preferences.gameintegration_vehicles) {
          this.updateVehicleMarkers(data.vehicles);
          if (data.removed) {
            this.removeDeadVehicles(data.removed);
          }
        }
      } else if(data.type === 'gl-events') {
        if(data.reset) this.clearEventMarkers();
        if(this.preferences.gameintegration_events) {
          this.updateEventMarkers(data.events);
          if (data.removed) {
            this.removeDeadEvents(data.removed);
          }
        }
      }
    },
    appendFeed(event, parameters) {
      this.feedMax++;
      let entry = {
        date: new Date(),
        event: event,
        pos: this.feedPosition,
        parameters: parameters
      };
      this.feed.splice(0, 0, entry);
      this.feedPosition++;
      this.refreshFeed();
    },
    async refreshFeed() {
      let feedContent = this.feed;
      this.feed = [];
      this.feed = uniqueElementsBy(feedContent,(a, b) => a.id == b.id);
    },
    async onPlayerJoin(data) {
      if(data.server.id !== this.server_id) return;
      if(this.getPlayer(data.gamesession.id) !== null) {
        this.$toast.error(`ERROR: Potential player desync or websocket lag (0x0)`);
        this.removePlayer(data.gamesession.id);
      }
      let player = {
        id: data.gamesession.id,
        cftools_id: data.user.id,
        created_at: parseISO8601String(data.created_at),
        persona: {},
        connection: {
          ipv4: null,
          provider: null,
          country_code: null,
          country_names: {},
          malicious: data.details.connection.vpn
        },
        gamedata: {
          player_name: data.details.player_name
        },
        live: {
          ping: {
            actual: null,
            trend: 0
          },
          loaded: false
        },
        info: data.info
      }

      if(this.permissions.pi) {
        player.connection.ipv4 = data.details.ipv4;
        player.connection.provider = data.details.connection.provider;
        player.connection.country_code = data.details.connection.country_code;
      }
      this.players.push(player);
    },
    async onPlayerUpdate(data) {
      if(data.server.id !== this.server_id) return;
      let player = this.getPlayer(data.gamesession.id);
      if(player === null) {
        this.$toast.error(`ERROR: Potential player desync or websocket lag (0x1)`);
      } else {
        player.persona = {
          profile: data.profile,
          bans: data.bans
        }
				player.info.radar = {
					results: {
						score: data.radar.live_score
					}
				}
      }
    },
    async onPlayerLeave(data) {
      if(data.server.id !== this.server_id) return;
      let player = this.getPlayer(data.gamesession.id);
      if(player !== null) {
        this.removePlayer(data.gamesession.id);
      }
      if(this.players.length === 0) {
        this.$nextTick(() => {
          this.refreshGameLabsPlayers();
        });
        setTimeout(() => {
          this.refreshGameLabsPlayers();
        }, 1000 * 10);
      }
    },
    /* ************************* */
    scrollFeedToBottom(force) {
      if(this.feedPinned && force !== true) return;
      try {
        this.$refs.feed.scrollTop = this.$refs.feed.scrollHeight;
        // eslint-disable-next-line no-empty
      } catch(e) {}
    },
    async scrollFeedTo(percentage) {
      let target = this.$refs.feed.scrollHeight - ((percentage / 100) * this.$refs.feed.scrollHeight);
      this.$refs.feed.scrollTop = target;
    },
    async handleScrolling() {
      let percentageTop = 100 - ((this.$refs.feed.scrollTop / this.$refs.feed.scrollHeight)*100);
      if(percentageTop >= 95) {
        if(this.feedPosition < this.feedMax) {
          await this.scrollFeedTo(94);
          await this.fetchFeed();
          await this.scrollFeedTo(94);
        }
      }
    },
    feedScrollLevel() {
      return 100 - ((this.$refs.feed.scrollTop / this.$refs.feed.scrollHeight)*100);
    },
    feedMouseEnter(event_id) {
      if(this.map.highlightLayer.length) {
        this.map.highlightLayer.forEach((el) => {
          el.remove();
        });
        this.map.highlightLayer = [];
      }
      let data;
      this.feed.forEach((e) => {
        if(e.id === event_id) {
          data = JSON.parse(JSON.stringify(e));
          return;
        }
      });
      if(!data) return;
      let tooltip;
      this.map.focusTimer = setTimeout(() => {
        if(['player.place', 'player.interact', 'player.envdeath', 'player.suicide'].includes(data.event)) {
          let position = [data.parameters.player.position[0], data.parameters.player.position[1]];
          position[0] = this.mapX(position[0]);
          position[1] = this.mapY(position[1]);
          let marker = L.marker(position.reverse(), {
            icon: L.divIcon({
              html: '<i class="fas fa-map-marker"></i>',
              iconSize: [9, 13],
              className: "c-highlight-marker"
            })
          });
          marker.addTo(this.$refs.gameMap.mapObject);
          tooltip = data.parameters.player.name + ' ' + this.localizeEvent(data, false);
          marker.bindTooltip(this.$sanitize(tooltip));
          this.map.highlightLayer.push(marker);
          this.$refs.gameMap.mapObject.setView(position, 5);
        } else if(['player.death'].includes(data.event)) {
          /* Player */
          let player_position = data.parameters.kill.position.to;
          player_position[0] = this.mapX(player_position[0]);
          player_position[1] = this.mapY(player_position[1]);
          let player_marker = L.marker(player_position.reverse(), {
            icon: L.divIcon({
              html: '<i class="fas fa-tombstone"></i>',
              iconSize: [9, 13],
              className: "c-highlight-marker"
            })
          });
          player_marker.addTo(this.$refs.gameMap.mapObject);
          tooltip = data.parameters.player.name;
          player_marker.bindTooltip(this.$sanitize(tooltip));
          this.map.highlightLayer.push(player_marker);

          /* Murderer */
          let murderer_position = data.parameters.kill.position.from;
          murderer_position[0] = this.mapX(murderer_position[0]);
          murderer_position[1] = this.mapY(murderer_position[1]);
          let murderer_marker = L.marker(murderer_position.reverse(), {
            icon: L.divIcon({
              html: '<i class="fas fa-map-marker"></i>',
              iconSize: [9, 13],
              className: "c-highlight-marker"
            })
          });
          murderer_marker.addTo(this.$refs.gameMap.mapObject);
          tooltip = data.parameters.murderer.name;
          murderer_marker.bindTooltip(this.$sanitize(tooltip));
          this.map.highlightLayer.push(murderer_marker);

          let line = new L.polyline([murderer_position, player_position], {
            color: '#ff00f7',
            weight: 2,
            opacity: 0.9,
            smoothFactor: 1
          });
          line.addTo(this.$refs.gameMap.mapObject);
          this.map.highlightLayer.push(line);

          let middle = [ (murderer_position[0] + player_position[0]) / 2, (murderer_position[1] + player_position[1]) / 2 ]
          this.$refs.gameMap.mapObject.setView(middle, 4);
        }
      }, 1);
    },
    feedMouseLeave() {
      if(this.map.focusTimer) clearTimeout(this.map.focusTimer);
    },
    waitForMapElement() {
      if(this.$refs.gameMap) this.setupMapElement();
      else setTimeout(() => this.waitForMapElement(), 100);
    },
    setupMapElement() {
      let that = this;
      L.TileLayer.CFCustom = L.TileLayer.extend({
        getTileUrl: function (coords) {
          return `${that.features.map.satellite_data.base_url}/${coords.z}/${coords.x}/${coords.y}.png`;
        }
      });
      L.tileLayer.cfCustom = function (options) {
        return new L.TileLayer.CFCustom(null, options);
      }

      this.map.tileLayer = new L.tileLayer.cfCustom({
        bounds: this.map.bounds,
        center: this.map.center,
        minNativeZoom: this.map.minZoom,
        maxNativeZoom: this.map.maxZoom,
        reuseTiles: false,
        noWrap: true,
        tms: false,
        continuousWorld: false,
        unloadInvisibleTiles: true
      });
      this.map.tileLayer.addTo(this.$refs.gameMap.mapObject);

      this.$refs.gameMap.mapObject.createPane('players');
      this.$refs.gameMap.mapObject.getPane('players').style.zIndex = 649;

      this.$refs.gameMap.mapObject.fitBounds(this.map.bounds);
      this.$refs.gameMap.mapObject.setMaxBounds(this.map.bounds);

      this.$nextTick(() => {
        this.$refs.gameMap.mapObject.invalidateSize(true);
        this.$nextTick(() => {
          this.refreshGameLabsPlayers();
          if(this.preferences.gameintegration_events) this.refreshGameLabsEvents();
          if(this.preferences.gameintegration_vehicles) this.refreshGameLabsVehicles();
        });
      });
    },
    async onMapReady() {
      if(!(this.features.map.available && this.features.map.satellite_data && this.permissions.map)) return;
      if(!this.map.initialized) {
        this.map.initialized = true;
      }

      window.dispatchEvent(new Event('resize'));
      this.$refs.gameMap.mapObject.fitBounds(this.map.bounds);
      this.$refs.gameMap.mapObject.setMaxBounds(this.map.bounds);
      this.$refs.gameMap.mapObject.invalidateSize(true);

      this.$refs.gameMap.mapObject.on('contextmenu', (event) => {
        event.originalEvent.preventDefault();
        if(this.features.gamelabs.poll_protocol < 2) return false;
        this.worldContextMenuOpen(this.map.x, this.map.y);
        return false;
      });

      [100, 200, 500].forEach((interval) => {
        setTimeout(() => {
          window.dispatchEvent(new Event('resize'));
          this.$refs.gameMap.mapObject.invalidateSize(true);
        }, interval);
      });
    },
    async onMouseOver(e) {
      this.map.x = e.latlng.lng;
      this.map.y = e.latlng.lat;
    },
    async onContextMenu(event) {
      event.preventDefault();
    },
    mapX(gameX) {
      return (this.map.units / this.map.width) * gameX;
    },
    mapY(gameY) {
      return -1*(this.map.units) + ((this.map.units / this.map.height) * gameY);
    },
    gameX(mapX) {
      return Number((this.map.width / this.map.units) * mapX).toFixed(3);
    },
    gameY(mapY) {
      return Number((this.map.height / this.map.units) * (mapY + this.map.units)).toFixed(3);
    },
    gameCoords(coords) {
      return `${this.gameX(coords[1])}, ${this.gameY(coords[0])}`;
    },
    addPlayerMarker(player) {
      let coords = player.position;
      coords[0] = this.mapX(coords[0]);
      coords[1] = this.mapY(coords[1]);
      let playerCircle = L.circleMarker(coords.reverse(), {
        radius: 3.5,
        fillColor: player.active ? '#00ff08' : '#ff7800',
        color: "#000",
        weight: 1.2,
        opacity: 1,
        fillOpacity: 1.0,
        riseOnHover: true,
        className: 'c-player-marker',
        draggable: true,
        zIndexOffset: 99999,
        pane: 'players'
      });

      playerCircle.options.id = player.id;
      playerCircle.options.name = player.name;
      playerCircle.options.teleportAllowed = (player.teleportAllowed === true || player.teleportAllowed === false) ? player.teleportAllowed : (player.insideVehicle !== 1);

      playerCircle.options.moving = false;
      playerCircle.options.liveCoords = coords;
      playerCircle.options.actualCoords = coords;

      let tooltip = `${player.name} (HP: ${player.health}, Hands: ${player.item || '-'})`;
      playerCircle.bindTooltip(this.$sanitize(tooltip));
      playerCircle.addTo(this.$refs.gameMap.mapObject);
      function trackCursor(evt) {
        playerCircle.setLatLng(evt.latlng);
        playerCircle.options.actualCoords = [evt.latlng.lat, evt.latlng.lng];
      }
      if(this.features.teleport && this.preferences.gameintegration_teleport) {
        playerCircle.on('mousedown', (event) => {
          if(event.originalEvent.button === 0) {
            // Left Click, Teleport Action
            if (!playerCircle.options.teleportAllowed) return;
            playerCircle.options.moving = true;
            playerCircle.setStyle({color: '#FFFFFF', fillColor: '#564ab1'});
            this.$refs.gameMap.mapObject.dragging.disable();
            this.$refs.gameMap.mapObject.on('mousemove', trackCursor);
          }
        });
        playerCircle.on('mouseup', (event) => {
          event.originalEvent.preventDefault();
          if(event.originalEvent.button === 0) {
            // Left Click, Teleport Action End
            if (!playerCircle.options.teleportAllowed) return;
            this.teleportTarget = playerCircle;
            this.teleportTarget.options.gameCoords = [this.gameX(this.teleportTarget.options.actualCoords[1]), this.gameY(this.teleportTarget.options.actualCoords[0])];
            this.$refs.teleportModal.show();
            playerCircle.options.moving = false;
            playerCircle.setStyle({color: '#000', fillColor: player.active ? '#00ff08' : '#ff7800'});
            this.$refs.gameMap.mapObject.dragging.enable();
            this.$refs.gameMap.mapObject.off('mousemove', trackCursor);
          }
        });
        playerCircle.on('contextmenu', (event) => {
          event.originalEvent.preventDefault();
          if(this.features.gamelabs.poll_protocol < 2) return false;
          this.playerContextAction = null;
          this.playerContext.meta = player;
          this.playerContext.target = player.id;
          this.$refs.playerContext.show();
          return false;
        });
      }
      this.map.players.push(playerCircle);
    },
    getPlayerMarkerById(id) {
      let marker;
      this.map.players.forEach((_marker) => {
        if(_marker.options.id === id) {
          marker = _marker;
        }
      });
      return marker;
    },
    getVehicleMarkerById(id) {
      let marker;
      this.map.vehicles.forEach((_marker) => {
        if(_marker.options.id === id) {
          marker = _marker;
        }
      });
      return marker;
    },
    getEventMarkerById(id) {
      let marker;
      this.map.events.forEach((_marker) => {
        if(_marker.options.id === id) {
          marker = _marker;
        }
      });
      return marker;
    },
    updatePlayerMarkers(players) {
      let online_players = [];
      players.forEach((player) => {
        player.active = (player.id === this.features.steam64);
        let marker = this.getPlayerMarkerById(player.id);
        online_players.push(player.id);
        if(marker) {
          let coords = player.position;
          coords[0] = this.mapX(coords[0]);
          coords[1] = this.mapY(coords[1]);
          coords.reverse();
          if(!marker.options.moving) {
            marker.setLatLng(coords);
          }
          let tooltip = `${player.name} (HP: ${player.health}, Hands: ${player.item || '-'})`;
          marker.bindTooltip(this.$sanitize(tooltip));
          marker.options.liveCoords = coords;
          if(player.teleportAllowed === true || player.teleportAllowed === false) marker.options.teleportAllowed = player.teleportAllowed;
          else if(player.insideVehicle === 0 || player.insideVehicle === 1) marker.options.teleportAllowed = (player.insideVehicle !== 1);
          else marker.options.teleportAllowed = true;

          if(this.player && this.player.gamedata.steam64 && this.player.gamedata.steam64 === player.id) {
            marker.setStyle({color: '#000', fillColor: '#4285F4'});
          } else if(!player.active) {
            marker.setStyle({color: '#000', fillColor: '#ff7800'});
          } else {
            marker.setStyle({color: '#000', fillColor: '#00ff08'});
          }
        } else this.addPlayerMarker(player);
      });
      let markers = [];
      this.map.players.forEach((marker) => {
        if(!online_players.includes(marker.options.id)) {
          marker.remove();
        } else {
          markers.push(marker);
        }
      });
      this.map.players = markers;
    },
    clearPlayerMarkers() {
      this.map.players.forEach((marker) => {
        marker.remove();
      });
      this.map.players = [];
    },
    //
    addVehicleMarker(vehicle) {
      let coords = vehicle.position;
      coords[0] = this.mapX(coords[0]);
      coords[1] = this.mapY(coords[1]);
      let marker = L.marker(coords.reverse(), {
        icon: L.divIcon({
          html: '<i class="far fa-car"></i>',
          iconSize: [12, 12],
          className: vehicle.speed > 0 ? "c-vehicle-marker-driving" : "c-vehicle-marker",
        }),
        zIndexOffset: -1
      });
      marker.options.id = vehicle.id;
      let tooltip = `${vehicle.className} (Health: ${vehicle.health}, ${vehicle.speed} km/h)`;
      marker.bindTooltip(this.$sanitize(tooltip));
      marker.addTo(this.$refs.gameMap.mapObject);

      marker.on('contextmenu', (event) => {
        event.originalEvent.preventDefault();
        if(this.features.gamelabs.poll_protocol < 2) return false;
        this.vehicleContextAction = null;
        this.vehicleContext.meta = vehicle;
        this.vehicleContext.target = vehicle.id;
        this.$nextTick(() => {
          this.$refs.vehicleContext.show();
        });
        return false;
      });
      this.map.vehicles.push(marker);
    },
    async onVehicleContextChange() {
      // Dummy method
    },
    async vehicleContextSubmit() {
      this.vehicleContext.submitted = true;
      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token,
          actionCode: this.vehicleContextAction.data.actionCode,
          actionContext: 'vehicle',
          referenceKey: this.vehicleContext.target,
          parameters: this.vehicleContextAction.data.parameters
        };
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/server/${this.server_id}/GameLabs/order`, {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        });
        if(response.ok) {
          this.$toast.success(`Action requested`);

          let data = await response.json();
          if(data.projected_execution !== null) {
            this.vehicleContext.awaiting_execution = true;
            setTimeout(() => {
              this.vehicleContext.awaiting_execution = false;
              this.vehicleContext.submitted = false;
              this.$refs.vehicleContext.hide();
              this.$toast.success(`Action acknowledged`);
            }, data.projected_execution);
          } else {
            this.$refs.vehicleContext.hide();
            this.vehicleContext.submitted = false;
          }
        } else {
          this.vehicleContext.submitted = false;
          if(response.status === 429) {
            this.$toast.warning(this.$t('error.server.ratelimit.short'));
          } else if(response.status === 403) {
            this.$toast.error('Forbidden');
          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        console.log(`[ERROR] ${error}`);
        this.$toast.error(this.$t('error.server.generic.message'));
        this.vehicleContext.submitted = false;
      }
    },
    async objectContextSubmit() {
      this.objectContext.submitted = true;
      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token,
          actionCode: this.objectContextAction.data.actionCode,
          actionContext: 'object',
          referenceKey: this.objectContext.target,
          parameters: this.objectContextAction.data.parameters
        };
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/server/${this.server_id}/GameLabs/order`, {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        });
        if(response.ok) {
          this.$toast.success(`Action requested`);

          let data = await response.json();
          if(data.projected_execution !== null) {
            this.objectContext.awaiting_execution = true;
            setTimeout(() => {
              this.objectContext.awaiting_execution = false;
              this.objectContext.submitted = false;
              this.$refs.objectContext.hide();
              this.$toast.success(`Action acknowledged`);
            }, data.projected_execution);
          } else {
            this.$refs.objectContext.hide();
            this.objectContext.submitted = false;
          }
        } else {
          this.objectContext.submitted = false;
          if(response.status === 429) {
            this.$toast.warning(this.$t('error.server.ratelimit.short'));
          } else if(response.status === 403) {
            this.$toast.error('Forbidden');
          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        console.log(`[ERROR] ${error}`);
        this.$toast.error(this.$t('error.server.generic.message'));
        this.objectContext.submitted = false;
      }
    },
    async onObjectContextChange() {
      if(this.objectContextAction.filter.length) {
        if(!this.objectContextAction.filter.includes(this.objectContext.meta.className)) {
          this.objectContext.error = true;
          return;
        }
      }
      this.objectContext.error = false;
    },
    async worldContextMenuOpen(x, y) {
      if(this.features.gamelabs.poll_protocol < 2) return false;
      this.worldContextAction = null;
      this.worldContext.meta.x = x;
      this.worldContext.meta.y = y;
      this.worldContext.target = null;
      this.$nextTick(() => {
        if(this.$refs.playerContext.isHidden) this.$refs.worldContext.show();
      });
    },
    async onWorldContextChange() {
      Object.keys(this.worldContextAction.data.parameters).forEach(key => {
        let param = this.worldContextAction.data.parameters[key];
        if(param.dataType === 'vector') {
          param.valueVectorX = this.gameX(this.worldContext.meta.x);
          param.valueVectorY = this.gameY(this.worldContext.meta.y);
        }
      });
    },
    async worldContextSubmit() {
      this.worldContext.submitted = true;
      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token,
          actionCode: this.worldContextAction.data.actionCode,
          actionContext: 'world',
          referenceKey: '',
          parameters: this.worldContextAction.data.parameters
        };
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/server/${this.server_id}/GameLabs/order`, {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        });
        if(response.ok) {
          this.$toast.success(`Action requested`);

          let data = await response.json();
          if(data.projected_execution !== null) {
            this.worldContext.awaiting_execution = true;
            setTimeout(() => {
              this.worldContext.awaiting_execution = false;
              this.worldContext.submitted = false;
              this.$refs.worldContext.hide();
              this.$toast.success(`Action acknowledged`);
            }, data.projected_execution);
          } else {
            this.$refs.worldContext.hide();
            this.worldContext.submitted = false;
          }
        } else {
          this.worldContext.submitted = false;
          if(response.status === 429) {
            this.$toast.warning(this.$t('error.server.ratelimit.short'));
          } else if(response.status === 403) {
            this.$toast.error('Forbidden');
          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        console.log(`[ERROR] ${error}`);
        this.$toast.error(this.$t('error.server.generic.message'));
        this.worldContext.submitted = false;
      }
    },
    async onPlayerContextChange() {
      // Dummy method
    },
    async playerContextSubmit() {
      this.playerContext.submitted = true;
      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token,
          actionCode: this.playerContextAction.data.actionCode,
          actionContext: 'player',
          referenceKey: this.playerContext.target,
          parameters: this.playerContextAction.data.parameters
        };
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/server/${this.server_id}/GameLabs/order`, {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        });
        if(response.ok) {
          let data = await response.json();
          if(data.projected_execution !== null) {
            this.playerContext.awaiting_execution = true;
            setTimeout(() => {
              this.playerContext.awaiting_execution = false;
              this.playerContext.submitted = false;
              this.$refs.playerContext.hide();
              this.$toast.success(`Action acknowledged`);
            }, data.projected_execution);
          } else {
            this.$refs.playerContext.hide();
            this.playerContext.submitted = false;
          }
        } else {
          this.playerContext.submitted = false;

          if(response.status === 429) {
            this.$toast.warning(this.$t('error.server.ratelimit.short'));
          } else if(response.status === 403) {
            this.$toast.error('Forbidden');
          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        console.log(`[ERROR] ${error}`);
        this.$toast.error(this.$t('error.server.generic.message'));
        this.playerContext.submitted = false;
      }
    },
    clearVehicleMarkers() {
      this.map.vehicles.forEach((marker) => {
        marker.remove();
      });
      this.map.vehicles = [];
    },
    removeDeadVehicles(removed) {
      let markers = [];
      this.map.vehicles.forEach((marker) => {
        if(removed.includes(marker.options.id)) {
          marker.remove();
        } else {
          markers.push(marker);
        }
      });
      this.map.vehicles = markers;
    },
    updateVehicleMarkers(vehicles) {
      vehicles.forEach((vehicle) => {
        let marker = this.getVehicleMarkerById(vehicle.id);
        if(marker) {
          let coords = vehicle.position;
          coords[0] = this.mapX(coords[0]);
          coords[1] = this.mapY(coords[1]);
          coords.reverse();
          marker.setLatLng(coords);
          let icon;
          if(vehicle.speed > 0) {
            icon = L.divIcon({
              html: '<i class="far fa-car"></i>',
              iconSize: [12, 12],
              className: "c-vehicle-marker-driving"
            });
          } else {
            icon = L.divIcon({
              html: '<i class="far fa-car"></i>',
              iconSize: [12, 12],
              className: "c-vehicle-marker"
            });
          }
          marker.setIcon(icon);
          let tooltip = `${vehicle.className} (HP: ${vehicle.health}, ${vehicle.speed} km/h)`;
          marker.bindTooltip(this.$sanitize(tooltip));
        } else this.addVehicleMarker(vehicle);
      });
    },
    //
    addEventMarker(event) {
      let coords = event.position;
      coords[0] = this.mapX(coords[0]);
      coords[1] = this.mapY(coords[1]);
      let marker = L.marker(coords.reverse(), {
        icon: L.divIcon({
          html: `<i class="far fa-${event.icon || 'location'}"></i>`,
          iconSize: [12, 12],
          className: "c-event-marker"
        }),
        zIndexOffset: 1
      });
      marker.options.id = event.id;
      let tooltip = `${event.displayName ? event.displayName : event.className}`;
      marker.bindTooltip(this.$sanitize(tooltip));
      marker.addTo(this.$refs.gameMap.mapObject);
      marker.on('contextmenu', (cevent) => {
        cevent.originalEvent.preventDefault();
        if(this.features.gamelabs.poll_protocol < 2) return false;
        this.objectContextAction = null;
        this.objectContext.meta = event;
        this.objectContext.target = event.id;
        this.$nextTick(() => {
          this.$refs.objectContext.show();
        });
        return false;
      });
      this.map.events.push(marker);
    },
    clearEventMarkers() {
      this.map.events.forEach((marker) => {
        marker.remove();
      });
      this.map.events = [];
    },
    removeDeadEvents(removed) {
      let markers = [];
      this.map.events.forEach((marker) => {
        if(removed.includes(marker.options.id)) {
          marker.remove();
        } else {
          markers.push(marker);
        }
      });
      this.map.events = markers;
    },
    updateEventMarkers(events) {
      events.forEach((event) => {
        let marker = this.getEventMarkerById(event.id);
        if(marker) {
          let coords = event.position;
          coords[0] = this.mapX(coords[0]);
          coords[1] = this.mapY(coords[1]);
          coords.reverse();
          marker.setLatLng(coords);
          let tooltip = `${event.className}`;
          marker.bindTooltip(this.$sanitize(tooltip));
        } else this.addEventMarker(event);
      });
    },
    async onPresenceChange(event) {
      if(!event.server_id) return;
      if(event.server_id !== this.server_id) return;
      let cftools_id = event.cftools_id;
      if(this.admins[cftools_id] && event.status === 0) {
        delete this.admins[cftools_id];
      } else {
        this.admins[cftools_id] = {
          date: new Date(),
          avatar: event.arguments.avatar,
          name: event.arguments.name
        };
        if(event.status === 1) {
          this.$socket.client.emit('presence', {type: 'server', server_id: this.server_id, status: 2, arguments: {avatar: this.getPersonaAvatar(), name: this.getPersonaName()}});
        }
      }
      this.visibleAdmins = [];
      this.visibleAdmins = Object.keys(this.admins);
    },
    async selectPlayer(player) {
      if(this.player && this.player === player) this.player = null;
      else this.player = player;
    },
    updateConnectionStatus(status) {
      this.serverStatus = status;
    }
  },
  async created() {
    this.preferences = this.getPreferences();
    if(this.timer === null) {
      this.timer = setInterval(() => {
        this.visibleAdmins = [];
        this.$socket.client.emit('presence', {type: 'server', server_id: this.server_id, status: 1, arguments: {avatar: this.getPersonaAvatar(), name: this.getPersonaName()}});
        this.currentDate = new Date();
        Object.keys(this.admins).forEach((cftools_id) => {
          if(new Date() - this.admins[cftools_id].date > 2 * 60 * 1000) {
            delete this.admins[cftools_id];
          }
        });
        this.visibleAdmins = Object.keys(this.admins);
      }, 60 * 1000);
    }
    this.console.version = this.options.console.version;
    this.console.commands = this.options.console.commands;

    this.server = this.options.server;
    this.features = this.options.features;
    Object.keys(this.features['available-actions']).forEach((i) => {
      let action = this.features['available-actions'][i];
      if(action.actionContext === 'player') {
        this.playerContextActions.push({
          name: action.actionName,
          data: action
        })
      } else if(action.actionContext === 'vehicle') {
        this.vehicleContextActions.push({
          name: action.actionName,
          data: action
        })
      } else if(action.actionContext === 'world') {
        this.worldContextActions.push({
          name: action.actionName,
          data: action
        })
      } else if(action.actionContext === 'object') {
        this.objectContextActions.push({
          name: action.actionName,
          data: action,
          filter: action.actionContextFilter
        })
      }
    });

    this.permissions = this.options.permissions;
    this.adjustSize();
    window.addEventListener("resize", this.adjustSize);
    await this.refreshPlayers();
    await this.fetchFeed();
    this.scrollFeedToBottom(true);

    this.$socket.client.on('server:feed', this.handleFeedEvent); // Update from server (Omega)
    this.$socket.client.on('server:update', this.handleServerUpdate); // Update from server (Omega)
    this.$socket.client.on('gsm:player:create', this.onPlayerJoin); // player join
    this.$socket.client.on('gsm:player:persona', this.onPlayerUpdate); // player join
    this.$socket.client.on('gsm:player:destruct', this.onPlayerLeave); // player leave

    this.$socket.client.on('presence:server', this.onPresenceChange);

    try {
      let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/server/${this.server_id}/gameLabsItems`, {
        method: 'GET',
        credentials: 'include'
      });
      if(response.ok) {
        let data = await response.json();
        if(data.items.length) this.items = data.items;
      }
    } catch (error) {
      console.log(`[ERROR] ${error}`);
    }

    this.features.map.unsupported = false;
	  this.features.map.unavailable_legal = false;

    if(this.features.map.available && this.features.map.satellite_data) {
      this.units = this.map.units = this.features.map.satellite_data.units;
      this.map.name = this.features.map.satellite_data.name;
      this.map.width = this.features.map.satellite_data.dimensions[0];
      this.map.height = this.features.map.satellite_data.dimensions[1];

      this.map.maxZoom = this.features.map.satellite_data.max_zoom;
      this.map.minZoom = this.features.map.satellite_data.min_zoom;
      this.map.zoom = this.map.minZoom;

      this.map.bounds = new L.LatLngBounds([[0, this.map.units], [-this.map.units, 0]]);
      this.map.center = [this.map.units / 2, -1 * (this.map.units / 2)];
      this.$nextTick(() => {
        this.map.ready = true;
      });
    }
  },
  mounted() {
    this.$socket.client.emit('join', {type: 'active', server_id: this.server_id});
    this.$socket.client.emit('join', {type: 'metrics', server_id: this.server_id});
    this.audio.audio_alert_admin.volume = 0.05;
    this.adjustSize();
    if(this.features.map.available && this.features.map.satellite_data) {
      this.waitForMapElement();
    }
    if(!this.admins[this.getAccountId()]) {
      this.admins[this.getAccountId()] = {
        date: new Date(),
        avatar: this.getPersonaAvatar(),
        name: this.getPersonaName()
      }
      this.visibleAdmins.push(this.getAccountId());
    }
    this.$nextTick(() => {
      this.$socket.client.emit('presence', {type: 'server', server_id: this.server_id, status: 1, arguments: {avatar: this.getPersonaAvatar(), name: this.getPersonaName()}});
    });
  },
  destroyed() {
    this.$socket.client.off('server:feed', this.handleFeedEvent); // Update from server (Omega)
    this.$socket.client.off('server:update', this.handleServerUpdate); // Update from server (Omega)
    this.$socket.client.off('gsm:player:create', this.onPlayerJoin); // player join
    this.$socket.client.off('gsm:player:persona', this.onPlayerUpdate); // player join
    this.$socket.client.off('gsm:player:destruct', this.onPlayerLeave); // player leave
    this.$socket.client.off('presence:server', this.onPresenceChange);
    this.$socket.client.emit('presence', {type: 'server', server_id: this.server_id, status: 0});
    this.$socket.client.emit('leave', {type: 'active', server_id: this.server_id});
    this.$socket.client.emit('leave', {type: 'metrics', server_id: this.server_id});
    if(this.timer !== null) clearInterval(this.timer);
  },
  watch: {
    '$socket.connected'() {
      if(!this.$socket.connected && this.connected) {
          this.connected = false;
      } else if(this.$socket.connected && !this.connected) {
        this.$socket.client.emit('join', {type: 'active', server_id: this.server_id});
        this.$socket.client.emit('join', {type: 'metrics', server_id: this.server_id});
      }
    }
  },
  data() {
    return {
      WorkerState:WorkerState,
      preferences: {},
      audio: {
        audio_alert_admin: new Audio(require('@/assets/audio/error.wav'))
      },
      connected: true,
      serverStatus: true,
      map: {
        focusTimer: null,
        highlightLayer: [],
        name: 'chernarus',
        width: 15360,
        height: 15360,
        units: 240,
        ready: false,
        initialized: false,
        tileLayer: null,
        panePlayers: null,
        crs: L.CRS.Simple,
        zoom: 1,
        bounds: null,
        center: null,
        minZoom: 1,
        maxZoom: 6,
        x: 0,
        y: 0,
        vehicles: [],
        players: [],
        events: []
      },
      player: null,
      admins: {},
      visibleAdmins: [],
      teleportTarget: null,
      vehicleContext: {
        meta: null,
        target: null,
        submitted: false,
        awaiting_execution: false
      },
      vehicleContextAction: null,
      vehicleContextActions: [],
      playerContext: {
        meta: null,
        target: null,
        submitted: false,
        awaiting_execution: false
      },
      playerContextAction: null,
      playerContextActions: [],
      worldContext: {
        meta: {
          x: null,
          y: null
        },
        target: null,
        submitted: false,
        awaiting_execution: false
      },
      worldContextAction: null,
      worldContextActions: [],
      objectContext: {
        meta: {
          x: null,
          y: null
        },
        target: null,
        submitted: false,
        error: false,
        awaiting_execution: false
      },
      objectContextAction: null,
      objectContextActions: [],
      healTarget: null,
      killTarget: null,
      spawnTarget: null,
      timer: null,
      currentDate: new Date(),
      ready: true,
      error: false,
      mobileView: false,
      height: 0,
      width: 0,
      feedHeight: 0,
      feedMax: 0,
      feedPosition: 0,
      displayMode: 0,
      searchQuery: null,
      sortField: 'playtime',
      sortDirection: -1,
      players: [],
      feed: [],
      feedPinned: false,
      feedLoading: false,
      features: {
        map: false,
        satellite_data: null
      },
      permissions: {
        feed: false,
        map: false
      },
      server: {},

      // Actions
      inProgress: false,
      serverMessage: null,
      kick: {
        target: null,
        reason: null
      },
      kickAll: {
        reason: null
      },
      message: {
        target: null,
        content: null
      },

      // Special
      console: {
        pending: false,
        command: null,
        version: null,
        commands: ['help', 'version'],
        messages: []
      },
      items: vanillaObjects
    }
  }
};
</script>

<template>
  <div ref="coreElement" style="overflow-y: hidden !important;">
    <template v-if="error">
      <div class="row">
        <div class="col-lg-12 col-sm-12">
          <div class="card border border-danger">
            <div class="card-header bg-transparent border-danger">
              <h5 class="my-0 text-danger">
                <i class="far fa-exclamation-circle mr-3"></i>
                {{$t('error.server.generic.title')}}
              </h5>
            </div>
            <div class="card-body pt-0">
              <h5 class="card-title mt-0"> {{$t('error.server.generic.component')}}</h5>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="c-dashboard-row" :style="mobileView ? {} : {'height': height+'px'}">
        <div class="c-dashboard-col">
          <!-- Main Server Dashboard -->
          <div class="card card-body c-dashboard-card" v-if="displayMode === 0">
            <div class="c-dashboard-row">
              <div class="c-players-col" style="padding-right: unset;" v-on:contextmenu="onContextMenu($event)">
                <b-tabs justified nav-class="nav-tabs-custom" content-class="text-muted w-100 h-100" class="w-100 h-100" ref="dashboardTabs">
                  <b-tab active :style="{'height': (height-35)+'px'}" class="overflow-auto">
                    <template v-slot:title class="border border-danger">
                      <span class="d-inline-block">
                        <i class="fas fa-users" />
                      </span>
                      <span class="d-none ml-1 d-sm-inline-block">
                          {{ $t('server.dashboard.players.title') }}
                      </span>
                    </template>
                    <template>
                      <div class="row mt-1 mb-1 ml-1 mr-3 pr-0 pl-0">
                        <div class="col-lg-3 col-sm-12">
                          <b-input-group size="sm" :prepend="$t('server.dashboard.players.search')">
                            <b-form-input v-model="searchQuery"></b-form-input>
                          </b-input-group>
                        </div>
                        <div class="col-lg-3 col-sm-12 sm-spacer">
                          <b-input-group size="sm" :prepend="$t('server.dashboard.players.sort.label')">
                            <b-form-select v-model="sortField">
                              <b-form-select-option value="playtime" selected="selected">
                                {{ $t('server.dashboard.players.sort.options.playtime') }}
                              </b-form-select-option>
                              <b-form-select-option value="name">
                                {{ $t('server.dashboard.players.sort.options.name') }}
                              </b-form-select-option>
                              <b-form-select-option value="kdratio">
                                {{ $t('server.dashboard.players.sort.options.kdratio') }}
                              </b-form-select-option>
                            </b-form-select>
                            <b-input-group-append>
                              <template v-if="sortDirection === -1">
                                <button class="btn btn-outline-primary btn-sm" v-on:click="sortDirection = 1;">
                                  <i class="fal fa-arrow-down" />
                                </button>
                              </template>
                              <template v-else>
                                <button class="btn btn-outline-primary btn-sm" v-on:click="sortDirection = -1;">
                                  <i class="fal fa-arrow-up" />
                                </button>
                              </template>

                            </b-input-group-append>
                          </b-input-group>
                        </div>
                      </div>
                      <div role="tablist">
                        <b-card no-body class="settings-collapse c-player hard-border" v-for="player in filteredPlayers" :key="player.id">
                          <a v-b-toggle="player.id" class="text-dark" href="javascript: void(0);" v-on:click="selectPlayer(player)">
                            <b-card-header header-tag="header" role="tab">
                              <div class="row c-player-row">
                                <div class="c-player-img">
                                  <template v-if="player.persona.profile">
                                    <img :src="player.persona.profile.avatar" class="rounded-circle header-profile-user">
                                  </template>
                                  <template v-else>
                                    <half-circle-spinner :animation-duration="900" :size="32" class="align-middle"/>
                                  </template>
                                </div>
                                <div class="c-player-names">
                                  <div class="c-truncate-text">
                                    <span>
                                      <CopyableText :text="player.gamedata.player_name"/>
                                    </span>
                                    <template v-if="player.connection.country_code !== null">
                                      <flag
                                          :iso="player.connection.country_code"
                                          :title="player.connection.country_names[getUILanguage()]"
                                          :squared="false"
                                      />
                                    </template>
                                  </div>
                                  <div class="small text-muted c-truncate-text" v-if="player.persona.profile">
                                    <i class="fab fa-steam"></i>
                                    <span>
                                      <CopyableText :text="player.persona.profile.name"/>
                                    </span>
                                  </div>
                                </div>
                                <template v-if="permissions.pi">
                                  <div class="c-player-connection">
                                    <b class="text-code">
                                      <CopyableText class="text-code" :text="player.connection.ipv4"/>
                                    </b>
                                    <div class="small text-muted c-truncate-text">
                                      <span>
                                        <CopyableText :text="player.connection.provider"/>
                                      </span>
                                    </div>
                                  </div>
                                </template>
                                <div class="c-player-kdratio" v-if="preferences.dashboard_kdratio">
                                  <b class="text-code">
                                    <template v-if="player.stats">
                                      {{ $n(calcKDRatio(player)) }} ({{ player.stats.kills || 0}}/{{ player.stats.deaths || 0 }})
                                    </template>
                                    <template v-else>
                                      0.00 (0/0)
                                    </template>
                                  </b>
                                  <div class="small text-muted">
                                    {{ $t('server.dashboard.players.kdratio') }}
                                  </div>
                                </div>
                                <template v-if="player.live.ping && player.live.ping.actual !== null && player.live.ping.actual >= 0">
                                  <div class="c-player-ping">
                                    <b class="text-code">
                                      {{ player.live.ping.actual }}ms
                                      <template v-if="player.live.ping.trend === 1">
                                        <i class="fal fa-chevron-double-up text-danger" />
                                      </template>
                                      <template v-else-if="player.live.ping.trend === -1">
                                        <i class="fal fa-chevron-double-down text-success" />
                                      </template>
                                      <template v-else>
                                        <!-- TODO: Icon for stable ping? -->
                                      </template>
                                    </b>
                                    <div class="small text-muted">
                                      {{ $t('server.dashboard.players.ping') }}
                                    </div>
                                  </div>
                                </template>
                                <div class="c-player-playtime">
                                  <b class="text-code">
                                    {{ playerPlaytime(currentDate, player.created_at) }}
                                  </b>
                                  <div class="small text-muted">
                                    {{ $t('server.dashboard.players.playtime') }}
                                  </div>
                                </div>
                                <template v-if="player.live && player.live.loaded === false">
                                  <div class="c-player-badge text-center">
                                      <span class="badge badge-primary text-black font-size-12">
                                        {{ $t('server.dashboard.players.loading') }}...
                                      </span>
                                  </div>
                                </template>
	                              <template v-if="player.connection.malicious">
		                              <div class="c-player-badge text-center">
                                    <span class="badge badge-warning text-black font-size-12">
                                      <i class="far fa-shield-alt" /> VPN
                                    </span>
		                              </div>
	                              </template>
                                <template v-if="player.persona.profile">
                                  <template v-if="player.persona.bans.vac > 0">
                                    <div class="c-player-badge text-center">
                                      <span class="badge badge-danger text-black font-size-12">
                                        {{ player.persona.bans.vac }} {{ $t('server.dashboard.players.vac') }}
                                      </span>
                                    </div>
                                  </template>

                                  <template v-if="player.persona.profile && player.persona.profile.private === true">
                                    <div class="c-player-badge text-center">
                                      <span class="badge badge-warning text-black font-size-12">
                                        {{ $t('server.dashboard.players.private') }}
                                      </span>
                                    </div>
                                  </template>
                                </template>
                                <template v-if="player.info && player.info.ban_count > 0">
                                  <div class="c-player-badge text-center">
                                      <span class="badge badge-warning text-black font-size-12">
                                        {{ $t('server.dashboard.players.bans', {count: player.info.ban_count}) }}
                                      </span>
                                  </div>
                                </template>

	                              <template v-if="features.radar">
		                              <template v-if="player.info.radar && player.info.radar !== null && player.info.radar.results && player.info.radar.results !== null && player.info.radar.results.score !== null">
			                              <template v-if="player.info.radar.results.score > 8">
				                              <div class="c-player-badge text-center">
                                      <span class="badge badge-danger text-black font-size-12">
                                        <i class="far fa-radar"/>
	                                      {{ $t('server.dashboard.players.radar.high') }}
                                      </span>
				                              </div>
			                              </template>
			                              <template v-else-if="player.info.radar.results.score >= 5">
				                              <div class="c-player-badge text-center">
                                      <span class="badge badge-warning text-black font-size-12">
                                        <i class="far fa-radar"/>
	                                      {{ $t('server.dashboard.players.radar.medium') }}
                                      </span>
				                              </div>
			                              </template>
		                              </template>
	                              </template>
	                              <template v-if="features.radar_raw">
		                              <template v-if="player.info.radar && player.info.radar !== null && player.info.radar.results && player.info.radar.results !== null && player.info.radar.results.score !== null">
			                              <div class="c-player-badge text-center">
	                                      <span class="badge badge-info text-black font-size-12">
	                                        <i class="far fa-radar"/>
		                                      {{ player.info.radar.results.score }}
	                                      </span>
			                              </div>
		                              </template>
	                              </template>

                              </div>
                            </b-card-header>
                          </a>
                          <b-collapse :id="player.id" accordion="my-accordion" role="tabpanel">
                            <b-card-body>
                              <b-card-text>
                                <div class="row justify-content-center">
                                  <div class="col-lg-5 col-sm-12">
                                    <div class="table-responsive">
                                      <table class="table table-nowrap mb-0">
                                        <tbody>
                                          <tr>
                                            <th scope="row">
                                              <h6 class="text-uppercase mb-0">
                                                {{ $t('server.dashboard.players.joined') }}
                                              </h6>
                                            </th>
                                            <td>
                                              {{ $d(player.created_at, 'datetime', getDTLocale()) }}
                                            </td>
                                          </tr>
                                          <tr v-show="player.live && player.live.load_time">
                                            <th scope="row">
                                              <h6 class="text-uppercase mb-0">
                                                {{ $t('server.dashboard.players.load_time') }}
                                              </h6>
                                            </th>
                                            <td>
                                              {{ player.live.load_time }}s
                                            </td>
                                          </tr>
                                          <tr v-show="permissions.pi">
                                            <th scope="row">
                                              <h6 class="text-uppercase mb-0">
                                                IP
                                              </h6>
                                            </th>
                                            <td>
                                              {{ player.connection.ipv4 }}
                                              <div class="small text-muted">
                                                {{ player.connection.provider }}
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                  <div class="col-lg-5 col-sm-12 sm-spacer">
                                    <template>
                                      <router-link :to="{name: 'profile', params: {cftools_id: player.cftools_id}}" target="_blank">
                                        <button class="btn btn-block btn-outline-primary mb-2">
                                          {{ $t('server.dashboard.players.profile') }}
                                        </button>
                                      </router-link>
                                    </template>
                                    <template v-show="permissions.kick">
                                      <button class="btn btn-block btn-outline-warning" v-on:click="showKickModal(player.id)">
                                        {{ $t('server.dashboard.players.kick.button') }}
                                      </button>
                                    </template>
                                    <template v-show="permissions.message_private">
                                      <button class="btn btn-block btn-outline-info" v-on:click="showMessageModal(player.id)">
                                        {{ $t('server.dashboard.players.message.button') }}
                                      </button>
                                    </template>
                                    <div class="row mt-2">
                                      <div class="col-lg-4 col-sm-12" v-show="features.heal">
                                        <button class="btn btn-outline-primary btn-block" v-on:click="showHealModal(player.id)">
                                          {{ $t('server.dashboard.game.heal.button') }}
                                        </button>
                                      </div>
                                      <div class="col-lg-4 col-sm-12" v-show="features.kill">
                                        <button class="btn btn-outline-danger btn-block" v-on:click="showKillModal(player.id)">
                                          {{ $t('server.dashboard.game.kill.button') }}
                                        </button>
                                      </div>
                                      <div class="col-lg-4 col-sm-12" v-show="features['item-spawn'] || features['item-spawn-ex']">
                                        <button class="btn btn-outline-info btn-block" v-on:click="showItemSpawnModal(player.id)">
                                          {{ $t('server.dashboard.game.itemspawn.button') }}
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </b-card-text>
                            </b-card-body>
                          </b-collapse>
                        </b-card>
                      </div>
                    </template>
                  </b-tab>
                  <b-tab class="h-100 w-100" >
                    <template v-slot:title>
                      <div v-on:click="onMapReady">
                      <span class="d-inline-block">
                        <i class="fas fa-map" />
                      </span>
                      <span class="d-none ml-1 d-sm-inline-block">
                          Map
                          <template v-if="features.map.available && features.map.satellite_data && permissions.map">
                            ({{ gameX(map.x) }}, {{ gameY(map.y) }})
                          </template>
                      </span>
                      </div>
                    </template>
                    <template v-if="(features.map.available && features.map.satellite_data && permissions.map)">
                      <l-map

                          ref="gameMap"
                          :crs="map.crs"
                          :zoom="map.zoom"

                          :minZoom="map.minZoom"
                          :maxZoom="map.maxZoom"
                          :options="{
                            tms: false,
                            noWrap: true,
                            reuseTiles: false,
                            maxBounds: map.bounds,
                            maxBoundsViscosity: 0.5,
                            attributionControl: false,
                            continuousWorld: false,
                            unloadInvisibleTiles: true
                          }
                          "
                          :style="{'height': is_mobile ? '70vh' : '93%', 'width': is_mobile ? '100vw' : '100%'}"
                          v-if="map.ready"
                          v-on:mousemove="onMouseOver($event)"
                      >
                      </l-map>
                    </template>
                    <template v-else>
                      <template v-if="!features.map.available || !features.map.satellite_data">
                        <div class="row mt-4">
                          <div class="col justify-content-center">
                            <div  class="info-component text-center align-middle mt-auto mb-auto">
                              <template v-if="features.map.satellite_data === false">
                                <div class="info-component-space">
                                  <i class="fad fa-map text-danger info-component-icon"></i>
                                </div>
                                <div class="row" style="margin-top: 20px;">
                                  <div class="col-lg-12">
                                    <h3 v-b-tooltip.hover>{{$t('server.dashboard.map.unsupported.title', {map: features.map.name})}}</h3>
                                    <h5 class="text-muted mb-2">{{$t('server.dashboard.map.unsupported.description', {map: features.map.name})}}</h5>
                                    <h4 class="text-muted mt-4">{{$t('server.dashboard.map.unsupported.prompt', {map: features.map.name})}}</h4>
                                  </div>
                                </div>
                              </template>
                              <template v-else-if="features.map.limited">
                                <div class="info-component-space">
                                  <i class="fad fa-map text-info info-component-icon"></i>
                                </div>
                                <div class="row" style="margin-top: 20px;">
                                  <div class="col-lg-12">
                                    <h3 v-b-tooltip.hover>Upgrade your plan to gain live position insights</h3>
                                    <h5 class="text-muted mb-2">Map live data is available with any of our paid plans</h5>
                                  </div>
                                </div>
                              </template>
                              <template v-else-if="!serverStatus">
                                <div class="info-component-space">
                                  <i class="fad fa-map text-danger info-component-icon"></i>
                                </div>
                                <div class="row" style="margin-top: 20px;">
                                  <div class="col-lg-12">
                                    <h3 v-b-tooltip.hover>CFTools Cloud not connected</h3>
                                    <h5 class="text-muted mb-2">RCon connection unavailable</h5>
                                  </div>
                                </div>
                              </template>
	                            <template v-else-if="features.map.unavailable_legal">
		                            <div class="info-component-space">
			                            <i class="fad fa-map text-danger info-component-icon"></i>
		                            </div>
		                            <div class="row" style="margin-top: 20px;">
			                            <div class="col-lg-12">
				                            <h3 v-b-tooltip.hover>This map is not supported</h3>
				                            <h5 class="text-muted mb-2">This map is no longer available due to a license dispute with the copyright holder</h5>
			                            </div>
		                            </div>
	                            </template>
                              <template v-else>
                                <div class="info-component-space">
                                  <i class="fad fa-map text-warning info-component-icon"></i>
                                </div>
                                <div class="row" style="margin-top: 20px;">
                                  <div class="col-lg-12">
                                    <h3 v-b-tooltip.hover title="Wow such empty 👻">{{$t('server.dashboard.map.empty.title')}}</h3>
                                    <h5 class="text-muted">{{$t('server.dashboard.map.empty.description')}}</h5>
                                  </div>
                                </div>
                                <div class="row mt-1">
                                  <div class="col">
                                    <a href="https://steamcommunity.com/sharedfiles/filedetails/?id=2464526692" target="_blank">
                                      <button class="btn btn-primary btn-lg text-uppercase">
                                        <h5 class="mb-0">
                                          {{$t('server.dashboard.map.empty.button')}}
                                        </h5>
                                      </button>
                                    </a>
                                  </div>
                                </div>
                              </template>
                            </div>
                          </div>
                        </div>
                      </template>
                      <template v-else-if="!permissions.map">
                        <div class="row mt-4">
                          <div class="col justify-content-center">
                            <div  class="info-component text-center align-middle mt-auto mb-auto">
                              <div class="info-component-space">
                                <i class="fas fa-do-not-enter text-danger info-component-icon"></i>
                              </div>
                              <div class="row" style="margin-top: 20px;">
                                <div class="col-lg-12" v-if="true">
                                  <h3 v-b-tooltip.hover title="Bad. Bonk." class="text-uppercase text-danger">{{$t('error.permissions.title')}}</h3>
                                  <h5 class="text-muted">{{$t('error.permissions.message')}}</h5>
                                  <h6 class="text-muted">{{$t('error.permissions.details')}}</h6>
                                </div>
                                <div class="col-lg-12" v-else>
                                  <h3 v-b-tooltip.hover title="Bad. Bonk." class="text-uppercase text-danger">{{$t('error.server.generic.title')}}</h3>
                                  <h5 class="text-muted">{{$t('error.server.generic.component')}}</h5>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>
                    </template>
                  </b-tab>

                  <b-tab class="h-100 w-100" v-if="is_mobile">
                    <template v-slot:title>
                      <div>
                        <span class="d-inline-block">
                          <i class="fas fa-list-alt" />
                        </span>
                        <span class="d-none ml-1 d-sm-inline-block">
                          Feed
                        </span>
                      </div>
                    </template>
                    <template v-if="permissions.message_server">
                      <div class="row mt-2">
                        <div class="col">
                          <div class="input-group bg-light">
                            <input type="text" v-on:keyup.enter="messageServer()" v-model="serverMessage" :placeholder="$t('server.dashboard.feed.send.placeholder')" class="form-control bg-transparent border-0">
                            <button class="btn btn-primary hard-border" v-on:click="messageServer()" :disabled="inProgress" :class="{'disabled': inProgress}">
                              <half-circle-spinner
                                  v-if="inProgress"
                                  :animation-duration="1200"
                                  :size="24"
                                  class="align-middle d-inline-block"
                              />
                              <template v-if="!inProgress">
                                <i class="fad fa-reply-all"></i>
                              </template>
                            </button>
                          </div>
                        </div>
                      </div>
                    </template>
                    <hr>
                    <template v-if="!permissions.feed">
                    <span class="text-muted text-uppercase">
                      {{ $t('server.dashboard.feed.permissions') }}
                    </span>
                    </template>
                    <template v-else>
                      <div class="c-intersecting-row">
                        <div class="c-pin-btn">
                          <button
                              class="btn btn-rounded btn-sm"
                              :class="{'btn-outline-info': !(feedPinned), 'btn-warning': (feedPinned), 'text-black': (feedPinned)}"
                              v-on:click="feedPinned = !(feedPinned)"
                          >
                            <i class="fad fa-map-pin" />
                            {{ $t('server.dashboard.feed.pin') }}
                          </button>
                        </div>
                        <div class="c-admins" v-if="this.visibleAdmins.length">
                          <div class="avatar-group">
                            <div class="avatar-group-item" v-for="cftools_id in this.visibleAdmins" :key="cftools_id">
                              <a
                                  :href="`/profile/${cftools_id}`"
                                  class="d-inline-block"
                                  target="_blank"
                              >
                                <img
                                    :src="admins[cftools_id].avatar"
                                    class="rounded-circle avatar-xs"
                                    v-b-tooltip.hover
                                    :title="admins[cftools_id].name"
                                    style="border:1px solid black"
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="c-feed" :style="{'height': feedHeight+'px'}" ref="feed" v-on:scroll="handleScrolling">
                        <div class="row c-feed-row"
                             v-for="event in reversedFeed"
                             :key="event.id"
                             :style="{'display': displayEvent(event) ? null : 'none'}"
                             v-on:click="feedMouseEnter(event.id)"
                        >
                          <template v-if="displayEvent(event)">
                            <div class="col-3 text-center">
                              <small>
                                {{ $d(event.date, 'datetime', getDTLocale()) }}
                              </small>
                            </div>
                            <div class="col-9">
                              <span v-if="event.parameters.user && event.parameters.user.id">
                                <router-link :to="{name: 'profile', params: {cftools_id: event.parameters.user.id}}" target="_blank">
                                  {{ event.parameters.player.name }}
                                </router-link>
                              </span>
                              <span v-else-if="event.parameters.player && event.parameters.player.id">
                                <router-link :to="{name: 'profile', params: {cftools_id: event.parameters.player.id}}" target="_blank">
                                  {{ event.parameters.player.name }}
                                </router-link>
                              </span>
                              <span class="badge badge-primary small" v-if="event.event === 'server.message'">
                                <i class="fal fa-comment "></i> Server
                              </span>
                              <span class="badge badge-primary small" v-else-if="event.event === 'server.directmessage'">
                                <i class="fal fa-comment "></i> => {{event.parameters.target}}
                              </span>
                              <span class="c-feed-text" v-if="event.event !== 'player.death'">
                                {{ localizeEvent(event) }}
                              </span>
                              <span class="c-feed-text" v-else>
                                <template v-if="event.parameters.murderer">
                                  {{ localizeEvent(event) }}
                                  <router-link :to="{name: 'profile', params: {cftools_id: event.parameters.murderer.id}}" target="_blank">
                                    {{ event.parameters.murderer.name }}
                                  </router-link>
                                  ({{event.parameters.kill.weapon}}, {{event.parameters.kill.distance}}m)
                                </template>
                                <template v-else>
                                  {{ localizeEvent(event) }}
                                </template>
                              </span>
                            </div>
                          </template>
                        </div>
                      </div>
                    </template>
                  </b-tab>
                </b-tabs>
              </div>
              <div class="c-feed-col" v-if="!is_mobile">
                <div :style="{'padding-right': mobileView ? 0 : '0.75rem'}">
                  <template v-if="permissions.message_server">
                    <div class="row mt-2">
                      <div class="col">
                        <div class="input-group bg-light">
                          <input type="text" v-on:keyup.enter="messageServer()" v-model="serverMessage" :placeholder="$t('server.dashboard.feed.send.placeholder')" class="form-control bg-transparent border-0">
                          <button class="btn btn-primary hard-border" v-on:click="messageServer()" :disabled="inProgress" :class="{'disabled': inProgress}">
                            <half-circle-spinner
                                v-if="inProgress"
                                :animation-duration="1200"
                                :size="24"
                                class="align-middle d-inline-block"
                            />
                            <template v-if="!inProgress">
                              <i class="fad fa-reply-all"></i>
                            </template>
                          </button>
                        </div>
                      </div>
                    </div>
                  </template>
                  <hr>
                  <template v-if="!permissions.feed">
                    <span class="text-muted text-uppercase">
                      {{ $t('server.dashboard.feed.permissions') }}
                    </span>
                  </template>
                  <template v-else>
                    <div class="c-intersecting-row">
                      <div class="c-pin-btn">
                        <button
                            class="btn btn-rounded btn-sm"
                            :class="{'btn-outline-info': !(feedPinned), 'btn-warning': (feedPinned), 'text-black': (feedPinned)}"
                            v-on:click="feedPinned = !(feedPinned)"
                        >
                          <i class="fad fa-map-pin" />
                          {{ $t('server.dashboard.feed.pin') }}
                        </button>
                      </div>
                      <div class="c-admins" v-if="this.visibleAdmins.length">
                        <div class="avatar-group">
                          <div class="avatar-group-item" v-for="cftools_id in this.visibleAdmins" :key="cftools_id">
                            <a
                                :href="`/profile/${cftools_id}`"
                                class="d-inline-block"
                                target="_blank"
                            >
                              <img
                                  :src="admins[cftools_id].avatar"
                                  class="rounded-circle avatar-xs"
                                  v-b-tooltip.hover
                                  :title="admins[cftools_id].name"
                                  style="border:1px solid black"
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="c-feed" :style="{'height': feedHeight+'px'}" ref="feed" v-on:scroll="handleScrolling">
                      <div class="row c-feed-row"
                           v-for="event in reversedFeed"
                           :key="event.id"
                           :style="{'display': displayEvent(event) ? null : 'none'}"

                           v-on:click="feedMouseEnter(event.id)"
                      >
                        <template v-if="displayEvent(event)">
                          <div class="col-3 text-center">
                            <small>
                              {{ $d(event.date, 'datetime', getDTLocale()) }}
                            </small>
                          </div>
                          <div class="col-9"> <!-- :class="{'text-danger': ['player.death', 'player.damage', 'player.suicide'].includes(event.event), 'text-white': ['user.join', 'user.leave', 'user.kicked'].includes(event.event)}" -->
                            <span v-if="event.parameters.user && event.parameters.user.id">
                              <router-link :to="{name: 'profile', params: {cftools_id: event.parameters.user.id}}" target="_blank">
                                {{ event.parameters.player.name }}
                              </router-link>
                            </span>
                            <span v-else-if="event.parameters.player && event.parameters.player.id">
                              <router-link :to="{name: 'profile', params: {cftools_id: event.parameters.player.id}}" target="_blank">
                                {{ event.parameters.player.name }}
                              </router-link>
                            </span>
                            <span class="badge badge-primary small" v-if="event.event === 'server.message'">
                              <i class="fal fa-comment "></i> Server
                            </span>
                            <span class="badge badge-primary small" v-else-if="event.event === 'server.directmessage'">
                              <i class="fal fa-comment "></i> => {{event.parameters.target}}
                            </span>
                            <span class="c-feed-text" v-if="event.event !== 'player.death'">
                              {{ localizeEvent(event) }}
                            </span>
                            <span class="c-feed-text" v-else>
                              <template v-if="event.parameters.murderer">
                                {{ localizeEvent(event) }}
                                <router-link :to="{name: 'profile', params: {cftools_id: event.parameters.murderer.id}}" target="_blank">
                                  {{ event.parameters.murderer.name }}
                                </router-link>
                                ({{event.parameters.kill.weapon}}, {{event.parameters.kill.distance}}m)
                              </template>
                              <template v-else>
                                {{ localizeEvent(event) }}
                              </template>
                            </span>
                          </div>
                        </template>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
          <!-- Player Profile CiC -->
          <div class="card card-body c-dashboard-card" v-if="displayMode === 1">
            <div class="row">
              <button v-on:click="displayMode = 0">
                change
              </button>
              displayMode == 1
            </div>
          </div>
        </div>
      </div>
    </template>

    <b-modal
        ref="healModal"
        size="md"
        :title="$t('server.dashboard.game.heal.title')"
        title-class="font-18"
        hide-footer
        content-class="hard-border"
    >
      <h5>
        {{$t('server.dashboard.game.heal.confirm.title')}}
      </h5>
      <div class="row" v-if="healTarget">
        <div class="col-lg-12 col-sm-12">
          <h5>{{ healTarget.name }}</h5>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-lg-12 col-sm-12">
          <button class="btn btn-primary btn-block" v-on:click="healPlayer">
            {{ $t('server.dashboard.game.heal.title') }}
          </button>
        </div>
      </div>
    </b-modal>
    <b-modal
        ref="killModal"
        size="md"
        :title="$t('server.dashboard.game.kill.title')"
        title-class="font-18"
        hide-footer
        content-class="hard-border"
    >
      <h5>
        {{$t('server.dashboard.game.kill.confirm.title')}}
      </h5>
      <div class="row" v-if="killTarget">
        <div class="col-lg-12 col-sm-12">
          <h5>{{ killTarget.name }}</h5>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-lg-12 col-sm-12">
          <button class="btn btn-primary btn-block" v-on:click="killPlayer">
            {{ $t('server.dashboard.game.kill.title') }}
          </button>
        </div>
      </div>
    </b-modal>
    <b-modal
        ref="spawnItemModal"
        size="md"
        :title="$t('server.dashboard.game.itemspawn.title')"
        title-class="font-18"
        hide-footer
        content-class="hard-border"
    >
      <h5>
        {{$t('server.dashboard.game.itemspawn.confirm.title')}}
      </h5>
      <div class="row" v-if="spawnTarget">
        <div class="col-lg-12 col-sm-12">
          <h5>{{ spawnTarget.name }}</h5>
        </div>
      </div>
      <div class="row mt-4" v-if="spawnTarget">
        <div class="col-lg-8 col-sm-12">
          {{ $t('server.dashboard.game.itemspawn.item.custom') }}
          <vue-bootstrap-typeahead
              v-model="spawnTarget.object"
              :data="items"
              :serializer="s => s.className"
          >
            <template slot="suggestion" slot-scope="{ data, htmlText }">
              <span v-html="htmlText"></span>&nbsp;<small class="text-muted">{{ data.displayName }}</small>
            </template>
          </vue-bootstrap-typeahead>
        </div>
        <div class="col-lg-4 col-sm-12">
          {{ $t('server.dashboard.game.itemspawn.item.quantity') }}
          <b-input type="number" v-model="spawnTarget.quantity"></b-input>
        </div>
      </div>
      <template v-if="features['item-spawn-ex']">
        <div class="row mt-4" v-if="spawnTarget">
          <div class="col-lg-6 col-sm-12">
            {{ $t('server.dashboard.game.itemspawn.item.debug') }}
            <b-checkbox v-model="spawnTarget.debug"></b-checkbox>
          </div>
          <div class="col-lg-6 col-sm-12">
            {{ $t('server.dashboard.game.itemspawn.item.stacked') }}
            <b-checkbox v-model="spawnTarget.stacked"></b-checkbox>
          </div>
        </div>
      </template>
      <div class="row mt-4">
        <div class="col-lg-12 col-sm-12">
          <button class="btn btn-primary btn-block" v-on:click="spawnItemForPlayer">
            {{ $t('server.dashboard.game.itemspawn.title') }}
          </button>
        </div>
      </div>
    </b-modal>
    <b-modal
        ref="teleportModal"
        size="md"
        :title="$t('server.dashboard.game.teleport.title')"
        title-class="font-18"
        hide-footer
        content-class="hard-border"
    >
      <h5>
        {{$t('server.dashboard.game.teleport.confirm.title')}}
      </h5>
      <div class="row" v-if="teleportTarget">
        <div class="col-lg-12 col-sm-12">
          <h5>
            {{ teleportTarget.options.name }} <small> <i class="fad fa-street-view" /> ({{ gameCoords(teleportTarget.options.liveCoords) }})</small>
          </h5>
          <h6 class="mt-4">{{$t('server.dashboard.game.teleport.confirm.coordinates')}}</h6>
          <div class="row mt-2 text-center">
            <div class="col-lg-6 col-sm-6">
              <b-input class="form-control" v-model="teleportTarget.options.gameCoords[0]" type="number" size="lg" />
            </div>
            <div class="col-lg-6 col-sm-6">
              <b-input class="form-control" v-model="teleportTarget.options.gameCoords[1]" type="number" size="lg" />
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-lg-12 col-sm-12">
          <button class="btn btn-primary btn-block" v-on:click="teleportPlayer">
            {{ $t('server.dashboard.game.teleport.title') }}
          </button>
        </div>
      </div>
    </b-modal>
    <b-modal
        ref="playerContext"
        size="lg"
        :title="'Player Menu'"
        title-class="font-18"
        hide-footer
        content-class="hard-border"
    >
      <template v-if="features['available-actions'] === false">
        <div class="row mt-4">
          <div class="col justify-content-center">
            <div  class="info-component text-center align-middle mt-auto mb-auto">
              <div class="info-component-space">
                <i class="fad fa-code-merge text-info info-component-icon"></i>
              </div>
              <div class="row" style="margin-top: 20px;">
                <div class="col-lg-12">
                  <h3 v-b-tooltip.hover>Upgrade your plan to use dynamic actions</h3>
                  <h5 class="text-muted mb-2">Dynamic actions are available with our paid plans</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-else-if="features['available-actions'].length === 0">
        <div class="row mt-4">
          <div class="col justify-content-center">
            <div  class="info-component text-center align-middle mt-auto mb-auto">
              <div class="info-component-space">
                <i class="fad fa-code-merge text-info info-component-icon"></i>
              </div>
              <div class="row" style="margin-top: 20px;">
                <div class="col-lg-12">
                  <h3 v-b-tooltip.hover>No actions available</h3>
                  <h5 class="text-muted mb-2">Verify GameLabs is updated and active</h5>
                  <h6 class="text-muted mb-2">GameLabs version v1.8 or newer required (Installed: {{ features.gamelabs.version }})</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="row" v-if="playerContext.meta">
          <div class="col-lg-12 col-sm-12">
            <h5>
              {{ playerContext.meta.name }}
            </h5>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-lg-12 col-sm-12">
            <multiselect
                v-model="playerContextAction"
                :options="playerContextActions"
                :show-labels="false"
                :allow-empty="false"
                :searchable="true"
            >
              <!-- @input="onPlayerContextChange()" -->
              <template slot="singleLabel" slot-scope="props">
                <div>
                  <span class="option__title align-middle h4" :class="{'text-danger': props.option.data.actionColour === 'danger', 'text-success': props.option.data.actionColour === 'success', 'text-warning': props.option.data.actionColour === 'warning'}">
                    <template v-if="props.option.data.actionIcon">
                      <i class="fad mr-2" :class="`fa-${props.option.data.actionIcon}`"/>
                    </template>
                    {{ props.option.name }}
                  </span>
                  <div class="option__desc d-inline align-middle">
                    <span class="ml-2">
                      {{ props.option.data.actionCode }}
                      <template v-if="props.option.data.actionCode.startsWith('CFCloud_')">
                        <small class="badge badge-primary">
                          <i class="fad fa-badge-check"/>
                          Verified
                        </small>
                      </template>
                    </span>
                  </div>
                </div>
              </template>
              <template slot="option" slot-scope="props">
                <div>
                  <div class="option__desc d-inline ml-2">
                    <span class="option__title align-middle h4" :class="{'text-danger': props.option.data.actionColour === 'danger', 'text-success': props.option.data.actionColour === 'success', 'text-warning': props.option.data.actionColour === 'warning'}">
                      <template v-if="props.option.data.actionIcon">
                        <i class="fad mr-2" :class="`fa-${props.option.data.actionIcon}`"/>
                      </template>
                      {{ props.option.name }}
                    </span>
                    <span class="option__small align-middle ml-2 h6">
                      {{ props.option.data.actionCode }}
                      <template v-if="props.option.data.actionCode.startsWith('CFCloud_')">
                        <small class="badge badge-info">
                          <i class="fad fa-badge-check"/>
                          Verified
                        </small>
                      </template>
                    </span>
                  </div>
                </div>
              </template>
            </multiselect>
            <hr>
            <template v-if="playerContextAction">
              <template v-if="Object.keys(playerContextAction.data.parameters).length === 0">
                <h6 class="text-uppercase text-center text-muted">NO PARAMETERS REQUIRED</h6>
              </template>
              <template v-else>
                <div class="row">
                  <div class="col-lg-6 col-md-12 col-sm-12 text-center mt-2" v-for="(details, key) in playerContextAction.data.parameters" :key="key">
                    <h6>{{ details.displayName }} <div class="text-muted">{{ details.description }}</div></h6>
                    <template v-if="details.dataType === 'boolean'">

                      <b-checkbox v-model="details.valueBoolean"></b-checkbox>

                    </template>
                    <template v-else-if="details.dataType === 'string'">

                      <b-input type="text" v-model="details.valueString"></b-input>

                    </template>
	                  <template v-else-if="details.dataType === 'webhook_url'">

		                  <b-input type="text" v-model="details.valueString"></b-input>

	                  </template>
                    <template v-else-if="details.dataType === 'vector'">

                      <div class="row text-center">
                        <div class="col-lg-6 col-sm-6">
                          <b-input class="form-control" v-model="details.valueVectorX" type="number" size="lg" />
                        </div>
                        <div class="col-lg-6 col-sm-6">
                          <b-input class="form-control" v-model="details.valueVectorY" type="number" size="lg" />
                        </div>
                        <!--
                        <div class="col-lg-4 col-sm-4">
                          <b-input class="form-control" v-model="details.valueVectorZ" type="number" size="lg" />
                        </div>
                        -->
                      </div>

                    </template>
                    <template v-else-if="details.dataType === 'int'">

                      <b-input type="number" v-model="details.valueInt"></b-input>

                    </template>
                    <template v-else-if="details.dataType === 'float'">

                      <b-input type="float" v-model="details.valueFloat"></b-input>

                    </template>
                    <template v-else-if="details.dataType === 'cf_itemlist'">

                      <vue-bootstrap-typeahead
                          v-model="details.valueString"
                          :data="items"
                          :serializer="s => s.className"
                      >
                        <template slot="suggestion" slot-scope="{ data, htmlText }">
                          <span v-html="htmlText"></span>&nbsp;<small class="text-muted">{{ data.displayName }}</small>
                        </template>
                      </vue-bootstrap-typeahead>

                    </template>
                  </div>
                </div>
              </template>
              <hr>
              <button class="btn btn-primary btn-block" @click="playerContextSubmit()" :disabled="playerContext.submitted === true" :class="{'disabled': playerContext.submitted === true}">
                <half-circle-spinner
                    v-if="playerContext.submitted === true"
                    :animation-duration="1200"
                    :size="16"
                    class="align-middle d-inline-block"
                />
                Request action
              </button>
              <template v-if="playerContext.awaiting_execution === true">
                <h6 class="text-uppercase text-center mt-2">
                  Awaiting confirmation...
                </h6>
              </template>
            </template>
          </div>
        </div>
      </template>
    </b-modal>

    <b-modal
        ref="vehicleContext"
        size="lg"
        :title="'Vehicle Menu'"
        title-class="font-18"
        hide-footer
        content-class="hard-border"
    >
      <template v-if="features['available-actions'] === false">
        <div class="row mt-4">
          <div class="col justify-content-center">
            <div  class="info-component text-center align-middle mt-auto mb-auto">
              <div class="info-component-space">
                <i class="fad fa-code-merge text-info info-component-icon"></i>
              </div>
              <div class="row" style="margin-top: 20px;">
                <div class="col-lg-12">
                  <h3 v-b-tooltip.hover>Upgrade your plan to use dynamic actions</h3>
                  <h5 class="text-muted mb-2">Dynamic actions are available with our paid plans</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-else-if="features['available-actions'].length === 0">
        <div class="row mt-4">
          <div class="col justify-content-center">
            <div  class="info-component text-center align-middle mt-auto mb-auto">
              <div class="info-component-space">
                <i class="fad fa-code-merge text-info info-component-icon"></i>
              </div>
              <div class="row" style="margin-top: 20px;">
                <div class="col-lg-12">
                  <h3 v-b-tooltip.hover>No actions available</h3>
                  <h5 class="text-muted mb-2">Verify GameLabs is updated and active</h5>
                  <h6 class="text-muted mb-2">GameLabs version v1.8 or newer required (Installed: {{ features.gamelabs.version }})</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="row" v-if="vehicleContext.meta">
          <div class="col-lg-12 col-sm-12">
            <h5>
              {{ vehicleContext.meta.className }} - HP: {{ vehicleContext.meta.health }} - Speed: {{ vehicleContext.meta.speed }} km/h
            </h5>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-lg-12 col-sm-12">
            <multiselect
                v-model="vehicleContextAction"
                :options="vehicleContextActions"
                :show-labels="false"
                :allow-empty="false"
                :searchable="true"
            >
              <!-- @input="onVehicleContextChange()" -->
              <template slot="singleLabel" slot-scope="props">
                <div>
                  <span class="option__title align-middle h4" :class="{'text-danger': props.option.data.actionColour === 'danger', 'text-success': props.option.data.actionColour === 'success', 'text-warning': props.option.data.actionColour === 'warning'}">
                    <template v-if="props.option.data.actionIcon">
                      <i class="fad mr-2" :class="`fa-${props.option.data.actionIcon}`"/>
                    </template>
                    {{ props.option.name }}
                  </span>
                  <div class="option__desc d-inline align-middle">
                    <span class="ml-2">
                      {{ props.option.data.actionCode }}
                      <template v-if="props.option.data.actionCode.startsWith('CFCloud_')">
                        <small class="badge badge-primary">
                          <i class="fad fa-badge-check"/>
                          Verified
                        </small>
                      </template>
                    </span>
                  </div>
                </div>
              </template>
              <template slot="option" slot-scope="props">
                <div>
                  <div class="option__desc d-inline ml-2">
                    <span class="option__title align-middle h4" :class="{'text-danger': props.option.data.actionColour === 'danger', 'text-success': props.option.data.actionColour === 'success', 'text-warning': props.option.data.actionColour === 'warning'}">
                      <template v-if="props.option.data.actionIcon">
                        <i class="fad mr-2" :class="`fa-${props.option.data.actionIcon}`"/>
                      </template>
                      {{ props.option.name }}
                    </span>
                    <span class="option__small align-middle ml-2 h6">
                      {{ props.option.data.actionCode }}
                      <template v-if="props.option.data.actionCode.startsWith('CFCloud_')">
                        <small class="badge badge-info">
                          <i class="fad fa-badge-check"/>
                          Verified
                        </small>
                      </template>
                    </span>
                  </div>
                </div>
              </template>
            </multiselect>
            <hr>
            <template v-if="vehicleContextAction">
              <template v-if="Object.keys(vehicleContextAction.data.parameters).length === 0">
                <h6 class="text-uppercase text-center text-muted">NO PARAMETERS REQUIRED</h6>
              </template>
              <template v-else>
                <div class="row">
                  <div class="col-lg-6 col-md-12 col-sm-12 text-center mt-2" v-for="(details, key) in vehicleContextAction.data.parameters" :key="key">
                    <h6>{{ details.displayName }} <div class="text-muted">{{ details.description }}</div></h6>
                    <template v-if="details.dataType === 'boolean'">

                      <b-checkbox v-model="details.valueBoolean"></b-checkbox>

                    </template>
                    <template v-else-if="details.dataType === 'string'">

                      <b-input type="text" v-model="details.valueString"></b-input>

                    </template>
	                  <template v-else-if="details.dataType === 'webhook_url'">

		                  <b-input type="text" v-model="details.valueString"></b-input>

	                  </template>
                    <template v-else-if="details.dataType === 'vector'">

                      <div class="row text-center">
                        <div class="col-lg-6 col-sm-6">
                          <b-input class="form-control" v-model="details.valueVectorX" type="number" size="lg" />
                        </div>
                        <div class="col-lg-6 col-sm-6">
                          <b-input class="form-control" v-model="details.valueVectorY" type="number" size="lg" />
                        </div>
                        <!--
                        <div class="col-lg-4 col-sm-4">
                          <b-input class="form-control" v-model="details.valueVectorZ" type="number" size="lg" />
                        </div>
                        -->
                      </div>

                    </template>
                    <template v-else-if="details.dataType === 'int'">

                      <b-input type="number" v-model="details.valueInt"></b-input>

                    </template>
                    <template v-else-if="details.dataType === 'float'">

                      <b-input type="float" v-model="details.valueFloat"></b-input>

                    </template>
                    <template v-else-if="details.dataType === 'cf_itemlist'">

                      <vue-bootstrap-typeahead
                          v-model="details.valueString"
                          :data="items"
                          :serializer="s => s.className"
                      >
                        <template slot="suggestion" slot-scope="{ data, htmlText }">
                          <span v-html="htmlText"></span>&nbsp;<small class="text-muted">{{ data.displayName }}</small>
                        </template>
                      </vue-bootstrap-typeahead>

                    </template>
                  </div>
                </div>
              </template>
              <hr>
              <button class="btn btn-primary btn-block" @click="vehicleContextSubmit()" :disabled="vehicleContext.submitted === true" :class="{'disabled': vehicleContext.submitted === true}">
                <half-circle-spinner
                    v-if="vehicleContext.submitted === true"
                    :animation-duration="1200"
                    :size="16"
                    class="align-middle d-inline-block"
                />
                Request action
              </button>
              <template v-if="vehicleContext.awaiting_execution === true">
                <h6 class="text-uppercase text-center mt-2">
                  Awaiting confirmation...
                </h6>
              </template>
            </template>
          </div>
        </div>
      </template>
    </b-modal>
    <b-modal
        ref="objectContext"
        size="lg"
        :title="`Object Menu - ${objectContext.meta.className}`"
        title-class="font-18"
        hide-footer
        content-class="hard-border"
    >
      <template v-if="features['available-actions'] === false">
        <div class="row mt-4">
          <div class="col justify-content-center">
            <div  class="info-component text-center align-middle mt-auto mb-auto">
              <div class="info-component-space">
                <i class="fad fa-code-merge text-info info-component-icon"></i>
              </div>
              <div class="row" style="margin-top: 20px;">
                <div class="col-lg-12">
                  <h3 v-b-tooltip.hover>Upgrade your plan to use dynamic actions</h3>
                  <h5 class="text-muted mb-2">Dynamic actions are available with our paid plans</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-else-if="features['available-actions'].length === 0">
        <div class="row mt-4">
          <div class="col justify-content-center">
            <div  class="info-component text-center align-middle mt-auto mb-auto">
              <div class="info-component-space">
                <i class="fad fa-code-merge text-info info-component-icon"></i>
              </div>
              <div class="row" style="margin-top: 20px;">
                <div class="col-lg-12">
                  <h3 v-b-tooltip.hover>No actions available</h3>
                  <h5 class="text-muted mb-2">Verify GameLabs is updated and active</h5>
                  <h6 class="text-muted mb-2">GameLabs version v1.8 or newer required (Installed: {{ features.gamelabs.version }})</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="row mt-2">
          <div class="col-lg-12 col-sm-12">
            <multiselect
                v-model="objectContextAction"
                :options="objectContextActions"
                :show-labels="false"
                :allow-empty="false"
                :searchable="true"
                @input="onObjectContextChange()"
            >
              <template slot="singleLabel" slot-scope="props">
                <div>
                  <span class="option__title align-middle h4" :class="{'text-danger': props.option.data.actionColour === 'danger', 'text-success': props.option.data.actionColour === 'success', 'text-warning': props.option.data.actionColour === 'warning'}">
                    <template v-if="props.option.data.actionIcon">
                      <i class="fad mr-2" :class="`fa-${props.option.data.actionIcon}`"/>
                    </template>
                    {{ props.option.name }}
                  </span>
                  <div class="option__desc d-inline align-middle">
                    <span class="ml-2">
                      {{ props.option.data.actionCode }}
                      <template v-if="props.option.data.actionCode.startsWith('CFCloud_')">
                        <small class="badge badge-primary">
                          <i class="fad fa-badge-check"/>
                          Verified
                        </small>
                      </template>
                    </span>
                  </div>
                </div>
              </template>
              <template slot="option" slot-scope="props">
                <div>
                  <div class="option__desc d-inline ml-2">
                    <span class="option__title align-middle h4" :class="{'text-danger': props.option.data.actionColour === 'danger', 'text-success': props.option.data.actionColour === 'success', 'text-warning': props.option.data.actionColour === 'warning'}">
                      <template v-if="props.option.data.actionIcon">
                        <i class="fad mr-2" :class="`fa-${props.option.data.actionIcon}`"/>
                      </template>
                      {{ props.option.name }}
                    </span>
                    <span class="option__small align-middle ml-2 h6">
                      {{ props.option.data.actionCode }}
                      <template v-if="props.option.data.actionCode.startsWith('CFCloud_')">
                        <small class="badge badge-info">
                          <i class="fad fa-badge-check"/>
                          Verified
                        </small>
                      </template>
                    </span>
                  </div>
                </div>
              </template>
            </multiselect>
            <hr>
            <template v-if="objectContextAction">
              <template v-if="Object.keys(objectContextAction.data.parameters).length === 0">
                <h6 class="text-uppercase text-center text-muted">NO PARAMETERS REQUIRED</h6>
              </template>
              <template v-else>
                <div class="row">
                  <div class="col-lg-6 col-md-12 col-sm-12 text-center mt-2" v-for="(details, key) in objectContextAction.data.parameters" :key="key">
                    <h6>{{ details.displayName }} <div class="text-muted">{{ details.description }}</div></h6>
                    <template v-if="details.dataType === 'boolean'">

                      <b-checkbox v-model="details.valueBoolean"></b-checkbox>

                    </template>
                    <template v-else-if="details.dataType === 'string'">

                      <b-input type="text" v-model="details.valueString"></b-input>

                    </template>
	                  <template v-else-if="details.dataType === 'webhook_url'">

		                  <b-input type="text" v-model="details.valueString"></b-input>

	                  </template>
                    <template v-else-if="details.dataType === 'vector'">

                      <div class="row text-center">
                        <div class="col-lg-6 col-sm-6">
                          <b-input class="form-control" v-model="details.valueVectorX" type="number" size="lg" />
                        </div>
                        <div class="col-lg-6 col-sm-6">
                          <b-input class="form-control" v-model="details.valueVectorY" type="number" size="lg" />
                        </div>
                        <!--
                        <div class="col-lg-4 col-sm-4">
                          <b-input class="form-control" v-model="details.valueVectorZ" type="number" size="lg" />
                        </div>
                        -->
                      </div>

                    </template>
                    <template v-else-if="details.dataType === 'int'">

                      <b-input type="number" v-model="details.valueInt"></b-input>

                    </template>
                    <template v-else-if="details.dataType === 'float'">

                      <b-input type="float" v-model="details.valueFloat"></b-input>

                    </template>
                    <template v-else-if="details.dataType === 'cf_itemlist'">

                      <vue-bootstrap-typeahead
                          v-model="details.valueString"
                          :data="items"
                          :serializer="s => s.className"
                      >
                        <template slot="suggestion" slot-scope="{ data, htmlText }">
                          <span v-html="htmlText"></span>&nbsp;<small class="text-muted">{{ data.displayName }}</small>
                        </template>
                      </vue-bootstrap-typeahead>

                    </template>
                  </div>
                </div>
              </template>
              <hr>
              <button class="btn btn-primary btn-block" @click="objectContextSubmit()" :disabled="objectContext.submitted === true || objectContext.error" :class="{'disabled': objectContext.submitted === true}">
                <half-circle-spinner
                    v-if="objectContext.submitted === true"
                    :animation-duration="1200"
                    :size="16"
                    class="align-middle d-inline-block"
                />
                Request action
              </button>
              <template v-if="objectContext.awaiting_execution === true">
                <h6 class="text-uppercase text-center mt-2">
                  Awaiting confirmation...
                </h6>
              </template>
              <template v-if="objectContext.error">
                <h6 class="text-uppercase text-center mt-2 text-danger">
                  Action not supported for {{ objectContext.meta.className }}
                </h6>
              </template>
            </template>
          </div>
        </div>
      </template>
    </b-modal>
    <b-modal
        ref="worldContext"
        size="lg"
        :title="(worldContext.meta.x && worldContext.meta.y) ? `World Actions (Coordinates ${gameX(worldContext.meta.x)}, ${gameY(worldContext.meta.y)})` : 'World Actions'"
        title-class="font-18"
        hide-footer
        content-class="hard-border"
    >
      <template v-if="features['available-actions'] === false">
        <div class="row mt-4">
          <div class="col justify-content-center">
            <div  class="info-component text-center align-middle mt-auto mb-auto">
              <div class="info-component-space">
                <i class="fad fa-code-merge text-info info-component-icon"></i>
              </div>
              <div class="row" style="margin-top: 20px;">
                <div class="col-lg-12">
                  <h3 v-b-tooltip.hover>Upgrade your plan to use dynamic actions</h3>
                  <h5 class="text-muted mb-2">Dynamic actions are available with our paid plans</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-else-if="features['available-actions'].length === 0">
        <div class="row mt-4">
          <div class="col justify-content-center">
            <div  class="info-component text-center align-middle mt-auto mb-auto">
              <div class="info-component-space">
                <i class="fad fa-code-merge text-info info-component-icon"></i>
              </div>
              <div class="row" style="margin-top: 20px;">
                <div class="col-lg-12">
                  <h3 v-b-tooltip.hover>No actions available</h3>
                  <h5 class="text-muted mb-2">Verify GameLabs is updated and active</h5>
                  <h6 class="text-muted mb-2">GameLabs version v1.8 or newer required (Installed: {{ features.gamelabs.version }})</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="row mt-2">
          <div class="col-lg-12 col-sm-12">
            <multiselect
                v-model="worldContextAction"
                :options="worldContextActions"
                :show-labels="false"
                :allow-empty="false"
                :searchable="true"
                @input="onWorldContextChange()"
            >
              <template slot="singleLabel" slot-scope="props">
                <div>
                  <span class="option__title align-middle h4" :class="{'text-danger': props.option.data.actionColour === 'danger', 'text-success': props.option.data.actionColour === 'success', 'text-warning': props.option.data.actionColour === 'warning'}">
                    <template v-if="props.option.data.actionIcon">
                      <i class="fad mr-2" :class="`fa-${props.option.data.actionIcon}`"/>
                    </template>
                    {{ props.option.name }}
                  </span>
                  <div class="option__desc d-inline align-middle">
                    <span class="ml-2">
                      {{ props.option.data.actionCode }}
                      <template v-if="props.option.data.actionCode.startsWith('CFCloud_')">
                        <small class="badge badge-primary">
                          <i class="fad fa-badge-check"/>
                          Verified
                        </small>
                      </template>
                    </span>
                  </div>
                </div>
              </template>
              <template slot="option" slot-scope="props">
                <div>
                  <div class="option__desc d-inline ml-2">
                    <span class="option__title align-middle h4" :class="{'text-danger': props.option.data.actionColour === 'danger', 'text-success': props.option.data.actionColour === 'success', 'text-warning': props.option.data.actionColour === 'warning'}">
                      <template v-if="props.option.data.actionIcon">
                        <i class="fad mr-2" :class="`fa-${props.option.data.actionIcon}`"/>
                      </template>
                      {{ props.option.name }}
                    </span>
                    <span class="option__small align-middle ml-2 h6">
                      {{ props.option.data.actionCode }}
                      <template v-if="props.option.data.actionCode.startsWith('CFCloud_')">
                        <small class="badge badge-info">
                          <i class="fad fa-badge-check"/>
                          Verified
                        </small>
                      </template>
                    </span>
                  </div>
                </div>
              </template>
            </multiselect>
            <hr>
            <template v-if="worldContextAction">
              <template v-if="Object.keys(worldContextAction.data.parameters).length === 0">
                <h6 class="text-uppercase text-center text-muted">NO PARAMETERS REQUIRED</h6>
              </template>
              <template v-else>
                <div class="row">
                  <div class="col-lg-6 col-md-12 col-sm-12 text-center mt-2" v-for="(details, key) in worldContextAction.data.parameters" :key="key">
                    <h6>{{ details.displayName }} <div class="text-muted">{{ details.description }}</div></h6>
                    <template v-if="details.dataType === 'boolean'">

                      <b-checkbox v-model="details.valueBoolean"></b-checkbox>

                    </template>
                    <template v-else-if="details.dataType === 'string'">

                      <b-input type="text" v-model="details.valueString"></b-input>

                    </template>
	                  <template v-else-if="details.dataType === 'webhook_url'">

		                  <b-input type="text" v-model="details.valueString"></b-input>

	                  </template>
                    <template v-else-if="details.dataType === 'vector'">

                      <div class="row text-center">
                        <div class="col-lg-6 col-sm-6">
                          <b-input class="form-control" v-model="details.valueVectorX" type="number" size="lg" />
                        </div>
                        <div class="col-lg-6 col-sm-6">
                          <b-input class="form-control" v-model="details.valueVectorY" type="number" size="lg" />
                        </div>
                        <!--
                        <div class="col-lg-4 col-sm-4">
                          <b-input class="form-control" v-model="details.valueVectorZ" type="number" size="lg" />
                        </div>
                        -->
                      </div>

                    </template>
                    <template v-else-if="details.dataType === 'int'">

                      <b-input type="number" v-model="details.valueInt"></b-input>

                    </template>
                    <template v-else-if="details.dataType === 'float'">

                      <b-input type="float" v-model="details.valueFloat"></b-input>

                    </template>
                    <template v-else-if="details.dataType === 'cf_itemlist'">

                      <vue-bootstrap-typeahead
                          v-model="details.valueString"
                          :data="items"
                          :serializer="s => s.className"
                      >
                        <template slot="suggestion" slot-scope="{ data, htmlText }">
                          <span v-html="htmlText"></span>&nbsp;<small class="text-muted">{{ data.displayName }}</small>
                        </template>
                      </vue-bootstrap-typeahead>

                    </template>
                  </div>
                </div>
              </template>
              <hr>
              <button class="btn btn-primary btn-block" @click="worldContextSubmit()" :disabled="worldContext.submitted === true" :class="{'disabled': worldContext.submitted === true}">
                <half-circle-spinner
                    v-if="worldContext.submitted === true"
                    :animation-duration="1200"
                    :size="16"
                    class="align-middle d-inline-block"
                />
                Request action
              </button>
              <template v-if="worldContext.awaiting_execution === true">
                <h6 class="text-uppercase text-center mt-2">
                  Awaiting confirmation...
                </h6>
              </template>
            </template>
          </div>
        </div>
      </template>
    </b-modal>
    <b-modal
        ref="serverModal"
        size="lg"
        :title="$t('server.dashboard.server.title')"
        title-class="font-18"
        hide-footer
        content-class="hard-border"
    >
      <div class="row justify-content-center" v-if="options.worker.state === WorkerState.CONNECTED">
        <div class="col-lg-6 col-sm-12">
          <div class="alert alert-info" v-if="Object.keys(server).length <= 0">
            {{ $t('server.dashboard.server.empty') }}
          </div>
          <div class="table-responsive" v-else>
            <table class="table table-nowrap mb-0">
              <tbody>
              <tr>
                <th scope="row">
                  <h6 class="text-uppercase mb-0">
                    {{ $t('server.dashboard.server.gametime') }}
                  </h6>
                </th>
                <td>
                  {{ server.environment.gametime }}
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="col-lg-6 col-sm-12">
          <template v-if="permissions.lock">
            <button class="btn btn-block btn-primary" v-on:click="toggleLock()" v-if="server.settings.locked === true" :disabled="inProgress" :class="{'disabled': inProgress}">
              <half-circle-spinner
                  v-if="inProgress"
                  :animation-duration="1200"
                  :size="16"
                  class="align-middle d-inline-block"
              />
              <template v-if="!inProgress">
                {{ $t('server.dashboard.server.unlock') }}
              </template>
            </button>

            <button class="btn btn-block btn-outline-warning" v-on:click="toggleLock()" v-else :disabled="inProgress" :class="{'disabled': inProgress}">
              <half-circle-spinner
                  v-if="inProgress"
                  :animation-duration="1200"
                  :size="16"
                  class="align-middle d-inline-block"
              />
              <template v-if="!inProgress">
                {{ $t('server.dashboard.server.lock.button') }}
              </template>
            </button>
          </template>
          <template v-if="permissions.kick">
            <button class="btn btn-block btn-outline-danger" v-on:click="showKickAllModal()" :disabled="inProgress" :class="{'disabled': inProgress}">
              <half-circle-spinner
                  v-if="inProgress"
                  :animation-duration="1200"
                  :size="16"
                  class="align-middle d-inline-block"
              />
              <template v-if="!inProgress">
                {{ $t('server.dashboard.server.kickall') }}
              </template>
            </button>
          </template>
          <template v-if="permissions.configuration && server.settings.whitelist.configured === true">
            <button class="btn btn-block btn-outline-warning" v-on:click="toggleWhitelist()" v-if="server.settings.whitelist.active === true" :disabled="inProgress" :class="{'disabled': inProgress}">
              <half-circle-spinner
                  v-if="inProgress"
                  :animation-duration="1200"
                  :size="16"
                  class="align-middle d-inline-block"
              />
              <template v-if="!inProgress">
              {{ $t('server.dashboard.server.whitelist.pause') }}
              </template>
            </button>
            <button class="btn btn-block btn-primary" v-on:click="toggleWhitelist()" v-else :disabled="inProgress" :class="{'disabled': inProgress}">
              <half-circle-spinner
                  v-if="inProgress"
                  :animation-duration="1200"
                  :size="16"
                  class="align-middle d-inline-block"
              />
              <template v-if="!inProgress">
              {{ $t('server.dashboard.server.whitelist.resume') }}
              </template>
            </button>
          </template>
          <template v-if="permissions.shutdown">
            <button class="btn btn-block btn-outline-danger" v-on:click="shutdownNow()" :disabled="inProgress" :class="{'disabled': inProgress}">
              <half-circle-spinner
                  v-if="inProgress"
                  :animation-duration="1200"
                  :size="16"
                  class="align-middle d-inline-block"
              />
              <template v-if="!inProgress">
              {{ $t('server.dashboard.server.shutdown.now') }}
              </template>
            </button>
            <button class="btn btn-block btn-outline-danger" v-on:click="scheduleShutdown()" :disabled="inProgress" :class="{'disabled': inProgress}">
              <half-circle-spinner
                  v-if="inProgress"
                  :animation-duration="1200"
                  :size="16"
                  class="align-middle d-inline-block"
              />
              <template v-if="!inProgress">
                {{ $t('server.dashboard.server.shutdown.scheduled') }}
              </template>
            </button>
            <button class="btn btn-block btn-outline-info" v-on:click="skipShutdown()" :disabled="inProgress" :class="{'disabled': inProgress}">
              <half-circle-spinner
                  v-if="inProgress"
                  :animation-duration="1200"
                  :size="16"
                  class="align-middle d-inline-block"
              />
              <template v-if="!inProgress">
                {{ $t('server.dashboard.server.shutdown.skip') }}
              </template>
            </button>
          </template>
        </div>
      </div>
      <div v-else>
        <div class="alert alert-info">
          {{ $t('server.dashboard.server.empty') }}
        </div>
      </div>
    </b-modal>
    <b-modal
        ref="consoleModal"
        size="lg"
        :title="$t('server.dashboard.console.title')"
        title-class="font-18"
        hide-footer
        v-if="permissions.console"
        content-class="hard-border"
        header-class="c-console-header"
        body-class="c-console-container"
    >
      <div class="c-console text-code" ref="consoleContainer">
        <div class="c-console-messages" ref="consoleMessages">
          <div class="text-muted">
            BattlEye RCon v{{console.version}} - Commands: clear, commands
          </div>
          <div class="c-console-message" v-for="message in console.messages" :key="message.id">
            {{ message.message }}
          </div>
        </div>
        <div class="c-console-prompt-row">
            <span class="c-console-prompt-header">
              <template v-if="console.pending">
                <half-circle-spinner
                    :animation-duration="1200"
                    :size="12"
                    class="align-middle"
                />
              </template>
              <template v-else>
                >
              </template>
            </span>
            <input
                ref="consolePrompt"
                class="c-console-prompt"
                autofocus="autofocus"
                v-model="console.command"
                type="text"
                autocorrect="off"
                autocapitalize="none"
                @focus="$event.target.select()"
                v-on:keyup.enter="consoleEnterHandler()"
            >
        </div>
      </div>
    </b-modal>
    <b-modal
        ref="kickModal"
        size="md"
        :title="$t('server.dashboard.players.kick.title')"
        title-class="font-18"
        hide-footer
        v-if="permissions.kick"
    >
      <div class="row">
        <div class="col">
          <input type="text" :placeholder="$t('server.dashboard.players.kick.placeholder')" class="form-control" v-model="kick.reason">
        </div>
      </div>
      <div class="row mt-2">
        <div class="col">
          <button class="btn btn-warning btn-block" v-on:click="kickPlayer()" :disabled="inProgress" :class="{'disabled': inProgress}">
            <half-circle-spinner
                v-if="inProgress"
                :animation-duration="1200"
                :size="16"
                class="align-middle d-inline-block"
            />
            <template v-if="!inProgress">
              {{ $t('server.dashboard.players.kick.button') }}
            </template>
          </button>
        </div>
      </div>
    </b-modal>
    <b-modal
        ref="kickAllModal"
        size="md"
        :title="$t('server.dashboard.players.kickall.title')"
        title-class="font-18"
        hide-footer
        v-if="permissions.kick"
    >
      <div class="row">
        <div class="col">
          <input type="text" :placeholder="$t('server.dashboard.players.kickall.placeholder')" class="form-control" v-model="kickAll.reason">
        </div>
      </div>
      <div class="row mt-2">
        <div class="col">
          <button class="btn btn-warning btn-block" v-on:click="kickAllPlayers()" :disabled="inProgress" :class="{'disabled': inProgress}">
            <half-circle-spinner
                v-if="inProgress"
                :animation-duration="1200"
                :size="16"
                class="align-middle d-inline-block"
            />
            <template v-if="!inProgress">
              {{ $t('server.dashboard.players.kickall.button') }}
            </template>
          </button>
        </div>
      </div>
    </b-modal>
    <b-modal
        ref="messageModal"
        size="md"
        :title="$t('server.dashboard.players.message.title')"
        title-class="font-18"
        hide-footer
        v-if="permissions.message_private"
    >
      <div class="row">
        <div class="col">
          <input type="text" :placeholder="$t('server.dashboard.players.message.placeholder')" class="form-control" v-model="message.content">
        </div>
      </div>
      <div class="row mt-2">
        <div class="col">
          <button class="btn btn-primary btn-block" v-on:click="messagePlayer()" :disabled="inProgress" :class="{'disabled': inProgress}">
            <half-circle-spinner
                v-if="inProgress"
                :animation-duration="1200"
                :size="16"
                class="align-middle d-inline-block"
            />
            <template v-if="!inProgress">
              {{ $t('server.dashboard.players.message.button') }}
            </template>
          </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>
